/* eslint-disable no-throw-literal */
import React from "react";
import { useDropdownApis } from "../../../shared";
import SimpleReactValidator from "simple-react-validator";
import { useImmer } from "use-immer";
import {
  createQrCodeApi,
  getQrCodeDetailsApi,
  updateQrCodeDetailsApi,
} from "../apis/qrCodeApis";
import { errorMessage, successMessage } from "../../../utils";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../store";

export const useAddOrEditQrCode = () => {
  const { qrCodeId } = useParams();
  const navigate = useNavigate();
  const {
    state: { stores },
  } = useDropdownApis({
    isStoreList: true,
  });
  const { appState } = React.useContext(AppContext);
  const { globalData } = appState;

  const [state, setState] = useImmer({
    list: {
      storeList: [],
      orderTypeList: [],
      storeListLoading: true,
      orderTypeListLoading: true,
    },
    formData: {
      remarks: "",
      location: "",
      store_id: null,
      type: null,
    },
    isSaveButtonLoading: false,
  });

  const [, setForceUpdate] = React.useState();

  // Array containing breadcrumb links for navigation
  const headBreadCrumbsLinks = [
    { name: "Dashboard", href: "/" },
    { name: "QR Code", href: "/qr-code" },
    {
      name: qrCodeId ? `Edit` : "Create",
      href: "",
    },
  ];

  // Reference for the QR code validation using SimpleReactValidator
  const qrCodeValidator = React.useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: setForceUpdate },
    })
  );

  /**
   * Fetches details of a QR code based on its ID and updates the state with the retrieved data.
   *
   * @async
   * @function getQrCodeDetails
   * @param {string} qrCodeId - The unique identifier of the QR code to fetch details for.
   * @throws {Object} Throws an error object containing a message if the API call fails or if the response is unsuccessful.
   *
   * @returns {Promise<void>} A promise that resolves when the function completes. It does not return any value.
   */
  const getQrCodeDetails = async (qrCodeId) => {
    triggerListLoading(true);
    try {
      const response = await getQrCodeDetailsApi(qrCodeId);
      const { success, message, data } = response;
      if (success) {
        const { remarks, location, store_id, store_name, type, type_name } =
          data;
        setState((draft) => {
          draft.formData = {
            location,
            remarks: remarks === null ? "" : remarks,
            store_id: { value: store_id, label: store_name },
            type: { value: type, label: type_name },
          };
        });
      } else {
        throw { response: { data: { message } } };
      }
    } catch (error) {
      const message = error.response?.data?.message;
      errorMessage(message);
    } finally {
      triggerListLoading(false);
    }
  };

  /**
   * Creates a new QR code with the provided data and updates the application state.
   *
   * @async
   * @function createQrCode
   * @param {Object} body - The data required to create the QR code.
   * @param {string} body.location - The location associated with the QR code.
   * @param {string} body.remarks - Remarks or notes for the QR code.
   * @param {string} body.store_id - The ID of the store linked to the QR code.
   * @param {string} body.type - The type of the QR code.
   * @throws {Object} Throws an error object containing a message if the API call fails or if the response is unsuccessful.
   *
   * @returns {Promise<void>} A promise that resolves when the function completes. It does not return any value.
   */
  const createQrCode = async (body) => {
    triggerSubmitButtonLoading(true);
    try {
      const response = await createQrCodeApi(body);
      const { success, message, data } = response;

      if (success) {
        setState((draft) => {
          draft.formData = data;
        });
        successMessage(message);
        redirectToList();
      } else {
        throw { response: { data: { message } } };
      }
    } catch (error) {
      const message =
        error.response?.data?.error ?? error.response?.data?.message;
      errorMessage(message);
    } finally {
      triggerSubmitButtonLoading(false);
    }
  };

  /**
   * Updates the details of an existing QR code identified by its unique ID.
   *
   * @async
   * @function updateQrCode
   * @param {Object} params - The parameters for the update operation.
   * @param {string} params.qrCodeId - The unique identifier of the QR code to be updated.
   * @param {Object} params.params - An object containing the fields to be updated, such as location and remarks.
   * @throws {Object} Throws an error object containing a message if the API call fails or if the response indicates an error.
   *
   * @returns {Promise<void>} A promise that resolves when the update operation is completed. It does not return any value.
   */
  const updateQrCode = async ({ qrCodeId, params }) => {
    triggerSubmitButtonLoading(true);
    try {
      const response = await updateQrCodeDetailsApi({ qrCodeId, params });
      const { success, message } = response;

      if (success) {
        successMessage(message);
        redirectToList();
      } else {
        throw { response: { data: { message } } };
      }
    } catch (error) {
      const message = error.response?.data?.message;
      errorMessage(message);
    } finally {
      triggerSubmitButtonLoading(false);
    }
  };

  /**
   * Handles the submission of the QR code form. Validates the form data and either creates a new QR code
   * or updates an existing one based on the presence of a QR code ID.
   *
   * @async
   * @function handleSubmit
   * @returns {Promise<void>} A promise that resolves when the submission process is completed. It does not return any value.
   */
  const handleSubmit = async () => {
    // Check if all form fields are valid
    if (qrCodeValidator.current.allValid()) {
      const body = {
        ...state.formData,
        store_id: state.formData.store_id.value,
        type: state.formData.type.value,
      };

      // If qrCodeId exists, update the existing QR code; otherwise, create a new one
      if (qrCodeId) await updateQrCode({ qrCodeId, params: body });
      else await createQrCode(body);
    } else {
      // If validation fails, show validation messages
      qrCodeValidator.current.showMessages();
      // Trigger a state update to re-render if needed
      setForceUpdate(1);
    }
  };

  // Redirects the user to the QR code list page
  const redirectToList = () => navigate("/qr-code");

  // Handles changes in the form fields and updates the corresponding state
  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setState((draft) => {
      draft.formData[name] = value;
    });
  };

  // Triggers loading status for a specific list, updating the state accordingly
  const triggerListLoading = ({ listName, status }) => {
    setState((draft) => {
      draft.list[listName] = status;
    });
  };

  // Triggers the loading state for the submit button
  const triggerSubmitButtonLoading = (status) => {
    setState((draft) => {
      draft.isSaveButtonLoading = status;
    });
  };

  // Effect to load store and order type lists when stores or globalData change
  React.useEffect(() => {
    triggerListLoading({ listName: "storeListLoading", status: true });
    triggerListLoading({ listName: "orderTypeListLoading", status: true });

    if (stores?.length > 0 && state.list.storeList.length === 0) {
      setState((draft) => {
        draft.list.storeList = stores.map((store) => ({
          label: store.name,
          value: store.id,
        }));
      });
      triggerListLoading({ listName: "storeListLoading", status: false });
    }

    if (
      globalData?.default_order_type?.length > 0 &&
      state.list.orderTypeList.length === 0
    ) {
      setState((draft) => {
        if (!qrCodeId) {
          draft.formData.type = globalData.default_order_type[0];
        }
        draft.list.orderTypeList = globalData.default_order_type;
      });
      triggerListLoading({ listName: "orderTypeListLoading", status: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stores, globalData]);

  // Effect to fetch QR code details when qrCodeId changes
  React.useEffect(() => {
    if (qrCodeId) {
      (async () => await getQrCodeDetails(qrCodeId))();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qrCodeId]);

  return {
    qrCodeId,
    headBreadCrumbsLinks,
    state,
    qrCodeValidator,
    handleSubmit,
    handleFormChange,
  };
};
