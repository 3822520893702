import React, { useContext, useEffect, useMemo } from "react";
import { AppContext } from "../../../store/AppScopeProvider";
import { useTabs } from "../../../shared/hooks/useTabs";
import { useImmer } from "use-immer";
import {
  getProducts,
  getProductDetailsById,
  getProductDetailsInfo,
  updateProductDetailsInfo,
  getProductAvailability,
  updateAvailablilityDate,
  updateProductPreOrder,
  updateProductStatus,
  getProductAvailabilityGroup,
  assignAGToProduct,
  deleteAGFromProduct,
  getProductOptionalGroup,
  assignOGToProduct,
  deleteOGFromProduct,
  getProductStores,
  assignStoresToProduct,
  deleteStoreFromProduct,
  createVariant,
  createProduct,
  updateProduct,
  deleteProductById,
  updateVariant,
  updateDefaultVariant,
  getProductPriceLevel,
  updateProductPriceLevel,
  updateProductVisibility,
  getProductBundleGroup,
  assignBundleToProduct,
  deleteBundleFromProduct,
  copyProduct,
  getProductBarcode,
  deleteBarcode,
  createBarcode,
  editBarcode,
  getProductCrossSelling,
  getProductSimilarProducts,
  deleteCrossSellingProduct,
  deleteCrossSimilarProducts,
  assignProductToCrossSelling,
  assignProductToSimilarProducts,
  changeStatusCrossSellingProduct,
  changeStatusSimilarProducts,
  getProductTagsList,
  getBumpScreenTagsList,
  getProductSeo,
  updateProductSeo,
  getAccountsApi,
  getMarketingTagsList,
} from "../api/ProductApi";
import { useDebounce } from "use-debounce";
import {
  successMessage,
  errorMessage,
  successRedMessage,
} from "../../../utils";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import {
  useActivityLog,
  useDropdownApis,
  usePermission,
} from "../../../shared";

export const useProducts = ({ isDetailsId = false, IsAdd = false }) => {
  const { addActivityLog } = useActivityLog();
  const navigate = useNavigate();
  const { appState } = useContext(AppContext);
  const { setCurentTab } = useTabs();
  const isCreate = usePermission("product-create");
  const IsEditable = usePermission("product-modify");
  const params = useParams();
  const location = useLocation();
  const isEditMode = Boolean(params?.id);

  const {
    globalData,
    currentTabs: { productDetails: currentTab },
  } = appState;

  const {
    bundleGroupsList,
    storeList,
    availabilityGroupList,
    modifierGroupsList,
    categoriesList,
    taxGroupList,
  } = useDropdownApis({
    isBundleGroupList: currentTab === "2" ? true : false,
    isStoreList: currentTab === "3" ? true : false,
    isAvailabilityGroupList: currentTab === "8" ? true : false,
    isModifierGroupList: currentTab === "9" ? true : false,
    isCategoryList: IsAdd,
    isTaxgroupList: IsAdd,
  });

  const [state, setState] = useImmer({
    productsDetails: {},
    variantsDetails: {},
    isBusy: false,
    isOpen: false,
    isDeleteOpen: false,
    deleteId: "",
    id: null,
    initialLoad: true,
    isSaveButtonBusy: false,
    productCategories: [],
    productTags: [],
    BumpScreenTags: [],
    marketingTags: [],
    taxGroup: [],
    productsList: {
      isLoading: true,
      listData: [],
    },
    filterApplied: {
      categories: [],
      product_types: [],
      bump_screen_tags: [],
      status: {},
      all: [],
      searchText: null,
    },
    copyProduct: {
      isOpen: false,
      id: null,
      productName: "",
      copyOptions: [],
      isBusy: false,
    },
    basicDetails: {
      isLoading: true,
      details: null,
    },
    detailsInfo: {
      isSaveButton: false,
      isEdit: false,
      isLoading: true,
      data: {
        description: "",
        ingredients: "",
        nutritional_information: [],
        nutritional_description: "",
        allergenes: "",
      },
    },
    availability: {
      isSaveButton: false,
      isLoading: true,
      details: {
        availability_start_date: null,
        availability_end_date: null,
        pre_order_status: 1,
        status: 1,
      },
    },
    availabilityGroup: {
      isSaveButton: false,
      isLoading: true,
      dialogLoading: true,
      dataList: [],
      isOpen: false,
      isDeleteOpen: false,
      allDataList: [],
      filteredList: [],
      selectedItems: [],
      deleteModalOpen: false,
      selectedId: null,
      searchText: null,
      dialogLoading: true,
    },
    optionalGroup: {
      isLoading: true,
      dialogLoading: true,
      isOpenEdit: false,
      dataList: [],
      isOpen: false,
      isDeleteOpen: false,
      allDataList: [],
      filteredList: [],
      selectedItems: [],
      deleteModalOpen: false,
      selectedId: null,
      searchText: null,
      page: 1,
    },
    stores: {
      isLoading: true,
      isSaveButton: false,
      dialogLoading: true,
      dataList: [],
      isOpen: false,
      isDeleteOpen: false,
      allDataList: [],
      filteredList: [],
      selectedItems: [],
      deleteModalOpen: false,
      selectedId: null,
      searchText: null,
    },
    updateDetails: {
      isLoading: true,
      isSaveButton: false,
      details: {},
      updateData: {
        alias: "",
        colour: "",
        tags: [],
        marketing_tags: [],
        modifier: [],
        has_variant: 0,
        kilojoules: "",
        variants: [
          {
            name: "",
            plu: "",
            old_plu: "",
            default: 1,
          },
        ],
        loyalty_card_type_id: [],
      },
      imageData: null,
    },
    varients: {
      isOpen: false,
      isDelete: false,
      id: null,
      isBusy: false,
      data: {
        name: "",
        plu: "",
        old_plu: "",
        default: 0,
      },
    },
    accountsList: {
      selectedAccountIds: [],
      listData: [],
    },
    priceLevel: {
      isSaveButton: false,
      isLoading: true,
      dataList: [],
      staticDataList: [],
    },
    bundleGroup: {
      isLoading: true,
      dialogLoading: true,
      isBusy: false,
      dataList: [],
      isOpen: false,
      isOpenEdit: false,
      varientId: null,
      isDeleteOpen: false,
      allDataList: [],
      filteredList: [],
      selectedItems: [],
      productId: null,
      selectedId: null,
      searchText: null,
      page: 1,
    },
    promotions: {
      isLoading: true,
      isSaveButton: false,
      isOpen: false,
      selectedItems: [],
      dialogLoading: true,
      searchText: null,
      type: null,
      crossSelling: {
        isLoading: true,
        dialogLoading: true,
        dataList: [],
        isOpen: false,
        isDeleteOpen: false,
        allDataList: [],
        filteredList: [],
        selectedItems: [],
        deleteModalOpen: false,
        selectedId: null,
        searchText: null,
        dialogLoading: true,
        alreadyInList: [],
      },
      similarProducts: {
        isLoading: true,
        dialogLoading: true,
        dataList: [],
        isOpen: false,
        isDeleteOpen: false,
        allDataList: [],
        filteredList: [],
        selectedItems: [],
        deleteModalOpen: false,
        selectedId: null,
        searchText: null,
        dialogLoading: true,
        alreadyInList: [],
      },
    },
    validation: {
      isValidated: true,
      start_date: null,
      end_date: null,
    },
    httpStatusCodes: {
      details: {},
    },

    barcode: {
      details: [
        {
          id: "",
          barcode: "",
          type: "",
        },
      ],
      isDisable: [],
    },
    seoDetails: {
      product_id: "",
      slug: "",
      meta_title: "",
      meta_description: "",
      image_description: "",
      image_alt_text: "",
    },
  });
  const { basicDetails } = state;
  const [debouncedText] = useDebounce(state.filterApplied.searchText, 1000);

  const handleModal = (type, state, data, data2) => {
    switch (type) {
      case "AG-add":
        if (!state) {
          setState((draft) => {
            draft.availabilityGroup.selectedItems = [];
            draft.availabilityGroup.filteredList = [];
            draft.availabilityGroup.isOpen = state;
          });
        } else {
          setState((draft) => {
            draft.availabilityGroup.isOpen = state;
          });
        }
        break;
      case "AG-from-product":
        setState((draft) => {
          draft.availabilityGroup.isDeleteOpen = state;
          draft.availabilityGroup.selectedId = data;
        });
        break;
      case "OG-add":
        if (!state) {
          setState((draft) => {
            draft.optionalGroup.isOpen = state;
            draft.optionalGroup.filteredList = [];
          });
        } else {
          setState((draft) => {
            draft.optionalGroup.isOpen = state;
            draft.optionalGroup.selectedItems = [];
            draft.optionalGroup.page = 1;
          });
        }
        break;
      case "OG-from-product":
        setState((draft) => {
          draft.optionalGroup.isDeleteOpen = state;
          draft.optionalGroup.selectedId = data;
        });
        break;
      case "OG-from-product-edit":
        if (state) {
          setState((draft) => {
            draft.optionalGroup.isOpenEdit = state;
            draft.optionalGroup.selectedId = data;
            draft.optionalGroup.selectedItems = [
              {
                id: data2.id,
                name: data2.name,
                min_selection: data2.min_selection,
                max_selection: data2.max_selection,
                control_type: data2.control_type,
              },
            ];
          });
        } else {
          setState((draft) => {
            draft.optionalGroup.isOpenEdit = state;
            draft.optionalGroup.selectedId = data;
          });
        }
        break;
      case "store-add":
        if (!state) {
          setState((draft) => {
            draft.stores.selectedItems = [];
            draft.stores.filteredList = [];
            draft.stores.isOpen = state;
          });
        } else {
          setState((draft) => {
            draft.stores.isOpen = state;
          });
        }
        break;
      case "store-from-product":
        setState((draft) => {
          draft.stores.isDeleteOpen = state;
          draft.stores.selectedId = data;
        });
        break;
      case "all-list-delete":
        setState((draft) => {
          draft.isOpen = state;
          draft.id = data;
        });
        break;
      case "varient-add":
        setState((draft) => {
          draft.varients.isOpen = state;
          draft.varients.id = null;
          draft.varients.data = {
            name: "",
            plu: "",
            old_plu: "",
            default: 0,
          };
        });
        break;
      case "varient-edit":
        const variantArry = basicDetails.details.variants.find(
          (e) => e.id === data
        );
        setState((draft) => {
          draft.variantsDetails = variantArry;
          draft.varients.isOpen = state;
          draft.varients.id = data;
          draft.varients.data = {
            name: variantArry.name,
            plu: variantArry.plu,
            old_plu: variantArry.old_plu,
            default: variantArry.default_variant,
          };
        });
        break;
      case "varient-delete":
        setState((draft) => {
          draft.varients.isDelete = state;
          draft.varients.id = data;
        });
        break;
      case "product-bundle-add":
        setState((draft) => {
          draft.bundleGroup.isOpen = state;
          draft.bundleGroup.varientId = data;
          draft.bundleGroup.page = 1;
        });
        break;
      case "product-bundle-edit":
        if (state) {
          setState((draft) => {
            draft.bundleGroup.isOpenEdit = state;
            draft.bundleGroup.selectedId = data;
            draft.bundleGroup.selectedItems = [
              {
                id: data2.id,
                name: data2.name,
                min_choice: data2.min_choice,
                max_choice: data2.max_choice,
                selection_type: data2.selection_type,
              },
            ];
          });
        } else {
          setState((draft) => {
            draft.bundleGroup.isOpenEdit = state;
            draft.bundleGroup.selectedId = data;
          });
        }

        break;
      case "product-bundle-delete":
        setState((draft) => {
          draft.bundleGroup.isDeleteOpen = state;
          draft.bundleGroup.selectedId = data;
          draft.bundleGroup.productId = data2;
        });
        break;
      case "delete-crossSelling":
        setState((draft) => {
          draft.promotions.crossSelling.isDeleteOpen = state;
          draft.promotions.crossSelling.selectedId = data;
        });
        break;
      case "delete-similarProducts":
        setState((draft) => {
          draft.promotions.similarProducts.isDeleteOpen = state;
          draft.promotions.similarProducts.selectedId = data;
        });
        break;
      case "crossSelling-add":
        setState((draft) => {
          draft.promotions.isOpen = state;
          draft.promotions.type = data;
          draft.promotions.selectedItems = [];
        });
        break;
    }
  };

  useEffect(() => {
    if (IsAdd) {
      HandleProductTags();
      HandleBumpScreeenTags();
      HandlemMarketingTags();
    }
  }, [state.productsList.isLoading, IsAdd]);

  //product Details

  useEffect(() => {
    if (isDetailsId && currentTab && Object.keys(globalData).length > 0) {
      HandleProducstDetails(isDetailsId);
    }
  }, [isDetailsId, currentTab, globalData]);

  useEffect(() => {
    if (isDetailsId) {
      if (!state.basicDetails.isLoading) {
        if (currentTab === "2") {
          HandleProductBundleGroup(isDetailsId);
        }
        if (currentTab === "3") {
          HandleProductsStores(isDetailsId);
        }
        if (currentTab === "4") {
          HandleProducstDetailsInfo(isDetailsId);
        }
        if (currentTab === "5") {
          HandleProducstAvailability(isDetailsId);
        }
        if (currentTab === "6") {
          HandleProductsCrossSelling(isDetailsId);
          HandleProductsSimilarProducts(isDetailsId);
        }
        if (currentTab === "8") {
          HandleProductsAvailabilityGroup(isDetailsId);
        }
        if (currentTab === "9") {
          HandleProductsOptionalGroup(isDetailsId);
        }
        if (currentTab === "11") {
          HandleProductsPriceLevel(isDetailsId);
        }
        if (currentTab === "12") {
          getBarcodeListData(isDetailsId);
        }
      }
    }
  }, [isDetailsId, currentTab, state.basicDetails.isLoading]);

  useEffect(() => {
    setState((draft) => {
      draft.stores.allDataList = storeList;
      draft.stores.dialogLoading = false;
    });
  }, [storeList]);

  useEffect(() => {
    setState((draft) => {
      draft.availabilityGroup.allDataList = availabilityGroupList;
      draft.availabilityGroup.dialogLoading = false;
    });
  }, [availabilityGroupList]);

  useEffect(() => {
    setState((draft) => {
      draft.optionalGroup.allDataList = modifierGroupsList;
      draft.optionalGroup.dialogLoading = false;
    });
  }, [modifierGroupsList]);

  useEffect(() => {
    setState((draft) => {
      draft.bundleGroup.allDataList = bundleGroupsList;
    });
  }, [bundleGroupsList]);

  const HandleProducstDetails = async (id) => {
    try {
      const res = await getProductDetailsById(id);
      if (res.success) {
        setState((draft) => {
          draft.productsDetails = res.data;
        });

        const modifiers = !res?.data?.modifier
          ? []
          : res?.data?.modifier?.split(",").map((v, i) => {
            return { value: v, label: v };
          });

        setState((draft) => {
          draft.basicDetails.details = res.data;
          draft.basicDetails.isLoading = false;
          draft.updateDetails.updateData = {
            ...draft.updateDetails.updateData,
            ...res.data,
            tax_group_id: res.data.tax_group?.id,
            category_id: res.data.category.id,
            modifier: modifiers,
            loyalty_card_type_id: res.data.loyalty_card_type_id,
          };
          draft.updateDetails.isLoading = false;
        });
      }
    } catch (err) {
      if (err?.response?.status === 303) {
        navigate(
          `/products/details/${err.response.data.data.parent_product_id}`
        );
        successRedMessage(
          "Information for specific product variants is not available.You’ve successfully navigated to the main product page."
        );
      } else {
        errorMessage(err.response.data.message);
        setState((draft) => {
          draft.basicDetails.isLoading = false;
          draft.updateDetails.isLoading = false;
          draft.httpStatusCodes.details = err.response.status;
        });
      }
    }
  };

  const HandleProducstDetailsInfo = async (id) => {
    try {
      const res = await getProductDetailsInfo(id);
      if (res.success) {
        setState((draft) => {
          draft.detailsInfo.data = {
            ...res.data,
            nutritional_information: res.data.nutritional_information
              ? res.data.nutritional_information
              : [],
          };
          draft.detailsInfo.isLoading = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.detailsInfo.isLoading = false;
      });
    }
  };

  const HandleDetailsInfoChange = (name, value) => {
    setState((draft) => {
      draft.detailsInfo.data = {
        ...draft.detailsInfo.data,
        [name]: value,
      };
      draft.detailsInfo.isSaveButton = false;
    });
  };

  const onsubmitDetailsInfo = async () => {
    if (state.detailsInfo.isEdit) {
      try {
        setState((draft) => {
          draft.detailsInfo.isSaveButton = true;
        });
        const res = await updateProductDetailsInfo(
          state.detailsInfo.data,
          isDetailsId
        );
        if (res.success) {
          successMessage("Updated Successfully");
          setState((draft) => {
            draft.detailsInfo.isSaveButton = false;
            draft.detailsInfo.isEdit = false;
          });
        }
      } catch (err) {
        errorMessage(err.response.data.message);
        setState((draft) => {
          draft.detailsInfo.isSaveButton = false;
        });
      }
    } else {
      setState((draft) => {
        draft.detailsInfo.isEdit = true;
        draft.detailsInfo.isSaveButton = true;
      });
      if (!state.detailsInfo.data.nutritional_information.length) {
        setState((draft) => {
          draft.detailsInfo.data = {
            ...draft.detailsInfo.data,
            nutritional_information: globalData.nutritional_information.map(
              (data) => {
                return {
                  name: data.label,
                  serving_quantity: "",
                  quantity: "",
                  unit: data.value,
                };
              }
            ),
          };
        });
      }
    }
  };

  useEffect(() => {
    if (currentTab === "4") {
      setState((draft) => {
        draft.detailsInfo.isSaveButton = false;
        draft.detailsInfo.isEdit = false;
      });
    }
  }, [currentTab]);

  const handleNestedInput = (actionType, event, index) => {
    const name = event?.target.name;
    const value = event?.target.value;
    if (actionType === "add") {
      setState((draft) => {
        draft.detailsInfo.data.nutritional_information = [
          ...draft.detailsInfo.data.nutritional_information,
          {
            name: "",
            serving_quantity: "",
            quantity: "",
          },
        ];
        draft.detailsInfo.isSaveButton = false;
      });
    } else if (actionType === "onChange") {
      setState((draft) => {
        draft.detailsInfo.data.nutritional_information = [
          ...draft.detailsInfo.data.nutritional_information.map((data, i) =>
            i === index ? { ...data, [name]: value } : data
          ),
        ];
        draft.detailsInfo.isSaveButton = false;
      });
    } else if (actionType === "delete") {
      setState((draft) => {
        draft.detailsInfo.data.nutritional_information = [
          ...draft.detailsInfo.data.nutritional_information.filter(
            (data, i) => i !== index
          ),
        ];
        draft.detailsInfo.isSaveButton = false;
      });
    }
  };

  //Availability

  const HandleProducstAvailability = async (id) => {
    try {
      const res = await getProductAvailability(id);
      if (res.success) {
        setState((draft) => {
          draft.availability.details = res.data;
          draft.availability.isLoading = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.availability.isLoading = false;
      });
    }
  };

  const handleAvailabilityDate = (value, name) => {
    setState((draft) => {
      draft.availability.details = {
        ...draft.availability.details,
        [name]: value,
      };
    });
  };

  const onSubmitAvailability = async (type) => {
    if (type === "date") {
      try {
        setState((draft) => {
          draft.availability.isSaveButton = true;
        });
        const data = {
          availability_start_date: state.availability.details
            .availability_start_date
            ? moment(state.availability.details.availability_start_date).format(
              "YYYY/MM/DD, h:mm:ss "
            )
            : null,
          availability_end_date: state.availability.details
            .availability_end_date
            ? moment(state.availability.details.availability_end_date).format(
              "YYYY/MM/DD, h:mm:ss "
            )
            : null,
        };
        const res = await updateAvailablilityDate(data, isDetailsId);
        if (res.success) {
          successMessage("Updated Successfully");
          setState((draft) => {
            draft.availability.isSaveButton = false;
          });
        }
      } catch (err) {
        errorMessage(err.response.data.message);
        setState((draft) => {
          draft.availability.isSaveButton = false;
        });
      }
    } else if (type === "pre-order") {
      try {
        setState((draft) => {
          draft.availability.isLoading = true;
        });

        const res = await updateProductPreOrder(isDetailsId);
        if (res.success) {
          successMessage("Updated Successfully");
          setState((draft) => {
            draft.availability.isLoading = false;
            draft.availability.details = res.data;
          });
        }
      } catch (err) {
        errorMessage(err.response.data.message);
        setState((draft) => {
          draft.availability.isLoading = false;
        });
      }
    } else if (type === "status") {
      try {
        setState((draft) => {
          draft.availability.isLoading = true;
        });

        const res = await updateProductStatus(isDetailsId);
        if (res.success) {
          successMessage("Updated Successfully");
          setState((draft) => {
            draft.availability.isLoading = false;
            draft.availability.details = res.data;
            draft.basicDetails.details = {
              ...draft.basicDetails.details,
              status: draft.basicDetails.details?.status === 1 ? 0 : 1,
            };
          });
        }
      } catch (err) {
        errorMessage(err.response.data.message);
        setState((draft) => {
          draft.availability.isLoading = false;
        });
      }
    } else if (type === "visibility") {
      try {
        setState((draft) => {
          draft.availability.isLoading = true;
        });
        const res = await updateProductVisibility(isDetailsId);
        if (res.success) {
          successMessage("Updated Successfully");
          setState((draft) => {
            draft.availability.isLoading = false;
            draft.availability.details = res.data;
          });
        }
      } catch (err) {
        errorMessage(err.response.data.message);
        setState((draft) => {
          draft.availability.isLoading = false;
        });
      }
    }
  };

  const validateDate = (reason, name, type) => {
    if (reason === "disablePast") {
      setState((draft) => {
        draft.validation[name] = "Please select a future date";
      });
    } else if (reason === "invalidDate") {
      setState((draft) => {
        draft.validation[name] =
          type === "date"
            ? "Selected Date is not a valid date"
            : "Selected Time is not valid";
      });
    } else {
      setState((draft) => {
        draft.validation[name] = null;
      });
    }
  };

  const isAllValid = () => {
    const { start_date, end_date } = state.validation;
    if (start_date === null && end_date === null) {
      return true;
    } else {
      return false;
    }
  };

  const isNotValidated = () => {
    setState((draft) => {
      draft.validation.isValidated = true;
    });
  };

  //Availability Group

  const HandleProductsAvailabilityGroup = async (id) => {
    try {
      const res = await getProductAvailabilityGroup(id);
      if (res.success) {
        setState((draft) => {
          draft.availabilityGroup.dataList = res.data;
          draft.availabilityGroup.isLoading = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.availabilityGroup.isLoading = false;
      });
    }
  };

  const HandleFilterAG = (type, text) => {
    const alreadyTaxesList = state.availabilityGroup.dataList.map((val) => {
      return val.id;
    });
    const filteredDataList = state.availabilityGroup.allDataList.filter(
      (val) => !alreadyTaxesList.includes(val.id)
    );
    if (type === "initial") {
      setState((draft) => {
        draft.availabilityGroup.searchText = "";
        draft.availabilityGroup.filteredList = filteredDataList;
        return draft;
      });
    } else {
      text = text.toLowerCase();
      setState((draft) => {
        draft.availabilityGroup.searchText = text;
        draft.availabilityGroup.filteredList = filteredDataList.filter(
          (val) => {
            return val.name.toString().toLowerCase().indexOf(text) > -1;
          }
        );
        return draft;
      });
    }
  };

  const handleAGSelect = (id) => {
    const isSelcted = state.availabilityGroup.selectedItems.includes(id);
    if (isSelcted) {
      setState((draft) => {
        draft.availabilityGroup.selectedItems =
          draft.availabilityGroup.selectedItems.filter((val) => val !== id);
      });
    } else {
      setState((draft) => {
        draft.availabilityGroup.selectedItems = [
          ...draft.availabilityGroup.selectedItems,
          id,
        ];
        return draft;
      });
    }
  };

  useEffect(() => {
    HandleFilterAG("initial");
  }, [state.availabilityGroup.allDataList, state.availabilityGroup.dataList]);

  const handleAssignAGToProduct = async () => {
    const data = {
      availability_group_id: state.availabilityGroup.selectedItems,
    };
    setState((draft) => {
      draft.availabilityGroup.isSaveButton = true;
    });

    try {
      const res = await assignAGToProduct(data, isDetailsId);
      if (res.success === true) {
        successMessage(res.message);
        setState((draft) => {
          draft.availabilityGroup.dataList = [
            ...draft.availabilityGroup.dataList,
            ...draft.availabilityGroup.allDataList.filter((val) =>
              draft.availabilityGroup.selectedItems.includes(val.id)
            ),
          ];
          draft.availabilityGroup.selectedItems = [];
          draft.availabilityGroup.isSaveButton = false;
          return draft;
        });
        handleModal("AG-add", false);
        HandleFilterAG("initial");
      }
    } catch (error) {
      errorMessage(error.response.data.message);
      setState((draft) => {
        draft.availabilityGroup.isSaveButton = false;
      });
    }
  };

  const handleDeleteAGFromProduct = async () => {
    try {
      const res = await deleteAGFromProduct(
        isDetailsId,
        state.availabilityGroup.selectedId
      );
      if (res.success) {
        successMessage("Deleted Successfully");
        setState((draft) => {
          draft.availabilityGroup.dataList =
            draft.availabilityGroup.dataList.filter(
              (item) => item.id !== state.availabilityGroup.selectedId
            );
        });
        handleModal("AG-from-product", false, null);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  // Modifier Groups

  const HandleProductsOptionalGroup = async (id) => {
    try {
      const res = await getProductOptionalGroup(id);
      if (res.success) {
        setState((draft) => {
          draft.optionalGroup.dataList = res.data;
          draft.optionalGroup.isLoading = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.optionalGroup.isLoading = false;
      });
    }
  };

  const HandleFilterOG = (type, text) => {
    const alreadyInList = state.optionalGroup.dataList.map((val) => {
      return val.id;
    });
    const filteredDataList = state.optionalGroup.allDataList.filter(
      (val) => !alreadyInList.includes(val.id)
    );
    if (type === "initial") {
      setState((draft) => {
        draft.optionalGroup.searchText = "";
        draft.optionalGroup.filteredList = filteredDataList;
        return draft;
      });
    } else {
      text = text.toLowerCase();
      setState((draft) => {
        draft.optionalGroup.searchText = text;
        draft.optionalGroup.filteredList = filteredDataList.filter((val) => {
          return val.name.toString().toLowerCase().indexOf(text) > -1;
        });
        return draft;
      });
    }
  };

  const handleOGSelect = (id) => {
    setState((draft) => {
      draft.optionalGroup.page = 2;
      draft.optionalGroup.selectedItems = [
        {
          id: id,
          min_selection: 0,
          max_selection: 1,
          control_type: 1,
        },
      ];
      return draft;
    });
  };

  useEffect(() => {
    HandleFilterOG("initial");
  }, [state.optionalGroup.allDataList, state.optionalGroup.dataList]);

  const handleAssignOGToProduct = async (type = "") => {
    const data = {
      optional_groups: state.optionalGroup.selectedItems,
    };

    setState((draft) => {
      draft.optionalGroup.isBusy = true;
    });

    try {
      const res = await assignOGToProduct(data, isDetailsId);
      if (res.success === true) {
        successMessage(res.message);
        setState((draft) => {
          draft.optionalGroup.isOpenEdit = false;
          draft.optionalGroup.isBusy = false;
          draft.optionalGroup.selectedId = null;
        });
        HandleFilterOG("initial");
        HandleProductsOptionalGroup(isDetailsId);
        if (type === "edit") {
          handleModal("OG-from-product-edit", false, null);
        } else {
          handleModal("OG-add", false);
        }
      }
    } catch (error) {
      setState((draft) => {
        draft.optionalGroup.isBusy = false;
      });
      errorMessage(error.response.data.message);
    }
  };

  const handleChangeOGInput = (event, id) => {
    const { name, value } = event.target;

    setState((draft) => {
      draft.optionalGroup.selectedItems = [
        ...draft.optionalGroup.selectedItems.map((data) =>
          data.id === id ? { ...data, [name]: value } : data
        ),
      ];
    });
  };

  const handleDeleteOGFromProduct = async () => {
    try {
      const res = await deleteOGFromProduct(
        isDetailsId,
        state.optionalGroup.selectedId
      );
      if (res.success) {
        successMessage("Deleted Successfully");
        setState((draft) => {
          draft.optionalGroup.dataList = draft.optionalGroup.dataList.filter(
            (item) => item.id !== state.optionalGroup.selectedId
          );
        });
        handleModal("OG-from-product", false, null);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  //Stores

  const HandleProductsStores = async (id) => {
    try {
      const res = await getProductStores(id);
      if (res.success) {
        setState((draft) => {
          draft.stores.dataList = res.data;
          draft.stores.isLoading = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.stores.isLoading = false;
      });
    }
  };

  const HandleFilterStore = (type, text) => {
    const alreadyInList = state.stores.dataList.map((val) => {
      return val.id;
    });
    const filteredDataList = state.stores.allDataList.filter(
      (val) => !alreadyInList.includes(val.id)
    );
    if (type === "initial") {
      setState((draft) => {
        draft.stores.searchText = "";
        draft.stores.filteredList = filteredDataList;
        return draft;
      });
    } else {
      text = text.toLowerCase();
      setState((draft) => {
        draft.stores.searchText = text;
        draft.stores.filteredList = filteredDataList.filter((val) => {
          return val.name.toString().toLowerCase().indexOf(text) > -1;
        });
        return draft;
      });
    }
  };

  const handleStoreSelect = (id) => {
    const isSelcted = state.stores.selectedItems.includes(id);
    if (isSelcted) {
      setState((draft) => {
        draft.stores.selectedItems = draft.stores.selectedItems.filter(
          (val) => val !== id
        );
      });
    } else {
      setState((draft) => {
        draft.stores.selectedItems = [...draft.stores.selectedItems, id];
        return draft;
      });
    }
  };

  useEffect(() => {
    HandleFilterStore("initial");
  }, [state.stores.allDataList, state.stores.dataList]);

  const handleAssignStoresToProduct = async () => {
    const data = {
      store_id: state.stores.selectedItems,
    };
    setState((draft) => {
      draft.stores.isSaveButton = true;
    });

    try {
      const res = await assignStoresToProduct(data, isDetailsId);
      if (res.success === true) {
        successMessage(res.message);
        setState((draft) => {
          draft.stores.dataList = [
            ...draft.stores.dataList,
            ...draft.stores.allDataList.filter((val) =>
              draft.stores.selectedItems.includes(val.id)
            ),
          ];
          draft.stores.selectedItems = [];
          draft.stores.isSaveButton = false;
          return draft;
        });
        handleModal("store-add", false);
        HandleFilterAG("initial");
      }
    } catch (error) {
      setState((draft) => {
        draft.stores.isSaveButton = false;
      });
      errorMessage(error.response.data.message);
    }
  };

  const handleDeleteStoreFromProduct = async () => {
    try {
      const res = await deleteStoreFromProduct(
        isDetailsId,
        state.stores.selectedId
      );
      if (res.success) {
        successMessage("Deleted Successfully");
        setState((draft) => {
          draft.stores.dataList = draft.stores.dataList.filter(
            (item) => item.id !== state.stores.selectedId
          );
        });
        handleModal("store-from-product", false, null);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  //ADD And EDit

  const activityLogUpdate = (res) => {
    const newData = res?.data;
    const { id, plu, name } = newData;
    const parameters = {
      ProductName: name,
      PLUcode: plu,
    };

    addActivityLog({
      templateId: 10,
      parameters: parameters,
      uniqueParameter: id,
      data: {
        newLogData: newData,
        oldLogData: state?.productsDetails,
      },
    });
  };

  const onUpdateProduct = async () => {
    try {
      setState((draft) => {
        draft.updateDetails.isSaveButton = true;
      });
      let formData = new FormData();
      const data = { ...state.updateDetails.updateData, _method: "PUT" };
      delete data.image;
      delete data.modifier;
      delete data.old_plu;
      delete data.tags;
      delete data.marketing_tags;
      delete data.bump_screen_tags;

      Object.keys(data).map((key) => {
        if (key !== "loyalty_card_type_id") {
          formData.append(key, data[key]);
        }
      });

      if (state.updateDetails.updateData.tags?.length > 0) {
        state.updateDetails.updateData.tags.map((v) => {
          formData.append("tags[]", v);
        });
      } else {
        formData.append(`tags[]`, "");
      }

      if (state.updateDetails.updateData.marketing_tags?.length > 0) {
        state.updateDetails.updateData.marketing_tags.map((v) => {
          formData.append("marketing_tags[]", v);
        });
      } else {
        formData.append(`marketing_tags[]`, "");
      }

      if (
        state.updateDetails.updateData.bump_screen_tags?.length > 0 &&
        state.updateDetails.updateData.product_type !== "bundle"
      ) {
        state.updateDetails.updateData.bump_screen_tags.map((v) => {
          formData.append("bump_screen_tags[]", v);
        });
      } else {
        formData.append(`bump_screen_tags[]`, "");
      }

      if (state.updateDetails.updateData.loyalty_card_type_id.length > 0) {
        state.updateDetails.updateData.loyalty_card_type_id.forEach(
          async (id) => {
            formData.append("loyalty_card_type_id[]", id);
          }
        );
      }

      if (state.updateDetails.imageData) {
        formData.append("image", state.updateDetails.imageData);
      }
      if (state.updateDetails.updateData.modifier?.length > 0) {
        state.updateDetails.updateData.modifier.map((v) => {
          formData.append("modifier[]", v.value);
        });
      }
      formData.append(`old_plu`, state.updateDetails.updateData.old_plu || "");

      const res = await updateProduct(isDetailsId, formData);
      if (res.success) {
        activityLogUpdate(res);
        successMessage("Update Successfully");
        setState((draft) => {
          draft.updateDetails.isSaveButton = false;
        });
        navigate(`/products/details/${isDetailsId}`);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.updateDetails.isSaveButton = false;
      });
    }
  };

  const activityLogCreation = (res) => {
    const newData = res?.data;
    const { id, product_type, plu, name } = newData;
    const parameters = {
      ProductName: name,
      ProductType: product_type,
      PLUcode: plu,
    };

    addActivityLog({
      templateId: 8,
      parameters: parameters,
      uniqueParameter: id,
      data: { newLogData: newData, oldLogData: {} },
    });
  };

  const onSaveProduct = async () => {
    try {
      setState((draft) => {
        draft.updateDetails.isSaveButton = true;
      });
      let formData = new FormData();
      Object.keys(state.updateDetails.updateData).forEach((key) => {
        if (key !== "loyalty_card_type_id") {
          formData.append(key, state.updateDetails.updateData[key]);
        }
      });
      if (state.updateDetails.updateData.has_variant) {
        state.updateDetails.updateData.variants.map((v, i) => {
          formData.append(`variants[${i}][name]`, v.name);
          formData.append(`variants[${i}][plu]`, v.plu);
          formData.append(`variants[${i}][old_plu]`, v.old_plu || "");
          formData.append(`variants[${i}][default]`, v.default);
        });
      }
      if (state.updateDetails.updateData.tags?.length > 0) {
        state.updateDetails.updateData.tags.map((v) => {
          formData.append("tags[]", v);
        });
      }
      if (state.updateDetails.updateData.marketing_tags?.length > 0) {
        state.updateDetails.updateData.marketing_tags.map((v) => {
          formData.append("marketing_tags[]", v);
        });
      }

      if (state.updateDetails.updateData.modifier?.length > 0) {
        state.updateDetails.updateData.modifier.map((v) => {
          formData.append("modifier[]", v.value);
        });
      }
      if (
        state.updateDetails.updateData.bump_screen_tags?.length > 0 &&
        state.updateDetails.updateData.product_type !== "bundle"
      ) {
        state.updateDetails.updateData.bump_screen_tags.map((v) => {
          formData.append("bump_screen_tags[]", v);
        });
      }

      if (state.updateDetails.updateData.loyalty_card_type_id.length > 0) {
        state.updateDetails.updateData.loyalty_card_type_id.forEach((id) => {
          formData.append("loyalty_card_type_id[]", id);
        });
      }

      if (
        state.accountsList.selectedAccountIds.length > 0 &&
        state.updateDetails.updateData.product_type !== "bundle"
      ) {
        // Construct Ids
        state.accountsList.selectedAccountIds.forEach(
          // Append the account_id to formData
          (acc) => formData.append("account_id[]", acc.id)
        );
      }
      state.updateDetails.imageData === null
        ? formData.delete("image")
        : formData.append("image", state.updateDetails.imageData);
      const res = await createProduct(formData);
      if (res.success) {
        activityLogCreation(res);
        successMessage("Created Successfully");
        setState((draft) => {
          draft.updateDetails.isSaveButton = false;
        });
        navigate(`/products/details/${res.data.id}`);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.updateDetails.isSaveButton = false;
      });
    }
  };

  const handleProductInputChange = (event, v) => {
    const name = event?.target.name;
    const value = event?.target.value;

    if (name === "image") {
      if (event.target.files.length !== 0) {
        setState((draft) => {
          draft.updateDetails.imageData = event.target.files[0];
          draft.updateDetails.updateData.image = URL.createObjectURL(
            event.target.files[0]
          );
        });
      }
    } else if (name === "has_variant") {
      setState((draft) => {
        draft.updateDetails.updateData.has_variant = v ? 1 : 0;
      });
    } else if (name === "account_id") {
      // Update account list
      setState((draft) => {
        draft.accountsList = {
          ...draft.accountsList,
          selectedAccountIds: value,
        };
      });
    } else {
      setState((draft) => {
        draft.updateDetails.updateData = {
          ...draft.updateDetails.updateData,
          [name]: value,
        };
      });
    }
  };

  const HandleProductTags = async () => {
    if (state.productTags.length === 0) {
      try {
        const res = await getProductTagsList();
        if (res.data?.length > 0) {
          setState((draft) => {
            draft.productTags = res.data;
            return draft;
          });
        }
      } catch (error) {
        errorMessage(error.response.data.message);
      }
    }
  };

  const HandleBumpScreeenTags = async () => {
    if (state.BumpScreenTags.length === 0) {
      try {
        const res = await getBumpScreenTagsList();
        if (res.data?.length > 0) {
          setState((draft) => {
            draft.BumpScreenTags = res.data;
            return draft;
          });
        }
      } catch (error) {
        errorMessage(error.response.data.message);
      }
    }
  };

  const HandlemMarketingTags = async () => {
    if (state.marketingTags.length === 0) {
      try {
        const res = await getMarketingTagsList();
        if (res.data?.length > 0) {
          setState((draft) => {
            draft.marketingTags = res.data;
            return draft;
          });
        }
      } catch (error) {
        errorMessage(error.response.data.message);
      }
    }
  };


  const handleDeleteProduct = async () => {
    try {
      const res = await deleteProductById(state.id);
      if (res.success) {
        successMessage("Deleted Successfully");
        setState((draft) => {
          draft.productsList.listData = draft.productsList.listData.filter(
            (item) => item.id !== state.id
          );
        });
        handleModal("all-list-delete", false, null);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const handleVarientNestedInput = (actionType, event, index) => {
    const name = event?.target.name;
    const value = event?.target.value;
    if (actionType === "add") {
      setState((draft) => {
        draft.updateDetails.updateData.variants = [
          ...draft.updateDetails.updateData.variants,
          {
            name: "",
            plu: "",
            default: 0,
          },
        ];
      });
    } else if (actionType === "Default") {
      setState((draft) => {
        draft.updateDetails.updateData.variants = [
          ...draft.updateDetails.updateData.variants.map((data, i) =>
            i === index ? { ...data, default: 1 } : { ...data, default: 0 }
          ),
        ];
      });
    } else if (actionType === "onChange") {
      setState((draft) => {
        draft.updateDetails.updateData.variants = [
          ...draft.updateDetails.updateData.variants.map((data, i) =>
            i === index ? { ...data, [name]: value } : data
          ),
        ];
      });
    } else if (actionType === "delete") {
      setState((draft) => {
        draft.updateDetails.updateData.variants = [
          ...draft.updateDetails.updateData.variants.filter(
            (data, i) => i !== index
          ),
        ];
      });
    }
  };

  const HandleVarientChange = (e, v) => {
    const name = e?.target.name;
    const value = e?.target.value;
    setState((draft) => {
      draft.varients.data = {
        ...draft.varients.data,
        [name]: value,
      };
    });
  };

  const activityLogCreateVariant = (res) => {
    const newData = res?.data;
    const parameterData = {
      ProductName: newData.name,
      PLUcode: newData.plu_code,
    };
    addActivityLog({
      templateId: 11,
      parameters: parameterData,
      uniqueParameter: newData.id,
      data: { newLogData: newData, oldLogData: {} },
    });
  };

  const activityLogUpdateVariant = (res) => {
    const newData = res?.data;
    const parameterData = {
      ProductName: newData.name,
      PLUcode: newData.plu_code,
    };
    const { default_variant, plu, ...rest } = state.variantsDetails;
    const oldActivityData = {
      default: default_variant,
      plu_code: plu,
      ...rest,
    };
    addActivityLog({
      templateId: 12,
      parameters: parameterData,
      uniqueParameter: newData.id,
      data: { newLogData: newData, oldLogData: oldActivityData },
    });
  };

  const handleCreateAndUpdatevarient = async (type, id) => {
    if (type === "edit") {
      try {
        setState((draft) => {
          draft.varients.isBusy = true;
        });
        const res = await updateVariant(
          id,
          state.varients.id,
          state.varients.data
        );
        if (res.success) {
          activityLogUpdateVariant(res);
          successMessage(res.message);
          setState((draft) => {
            draft.varients.isBusy = false;
            draft.basicDetails.details.variants =
              state.basicDetails.details.variants.map((data) => {
                if (res.data.default) {
                  return data.id === state.varients.id
                    ? {
                      ...res.data,
                      default_variant: res.data.default,
                      plu: res.data.plu_code,
                    }
                    : { ...data, default_variant: 0 };
                } else {
                  return data.id === state.varients.id
                    ? {
                      ...res.data,
                      default_variant: res.data.default,
                      plu: res.data.plu_code,
                    }
                    : data;
                }
              });
          });
          handleModal("varient-add", false, null);
        }
      } catch (err) {
        setState((draft) => {
          draft.varients.isBusy = false;
        });
        errorMessage(err.response.data.message);
      }
    } else {
      try {
        setState((draft) => {
          draft.varients.isBusy = true;
        });
        const res = await createVariant(id, state.varients.data);
        if (res.success) {
          activityLogCreateVariant(res);
          successMessage(res.message);
          setState((draft) => {
            draft.varients.isBusy = false;
            draft.basicDetails.details.variants = [
              ...draft.basicDetails.details.variants.map((data) => {
                return res.data.default
                  ? { ...data, default_variant: 0 }
                  : data;
              }),
              {
                ...res.data,
                default_variant: res.data.default,
                plu: res.data.plu_code,
              },
            ];
          });
          handleModal("varient-add", false, null);
        }
      } catch (err) {
        setState((draft) => {
          draft.varients.isBusy = false;
        });
        errorMessage(err.response.data.message);
      }
    }
  };

  const handleDeleteVariant = async () => {
    try {
      const res = await deleteProductById(state.varients.id);
      if (res.success) {
        successMessage("Deleted Variants Successfully");
        let varintes = [];
        if (state.basicDetails.details.variants.length > 1) {
          if (
            state.basicDetails.details.variants.find(
              (val) => val.id === state.varients.id
            ).default_variant === 1
          ) {
            varintes = state.basicDetails.details.variants
              .filter((item) => item.id !== state.varients.id)
              .map((val, index) => {
                return index === 0 ? { ...val, default_variant: 1 } : val;
              });
          } else {
            varintes = state.basicDetails.details.variants.filter(
              (item) => item.id !== state.varients.id
            );
          }
        }
        setState((draft) => {
          draft.basicDetails.details.variants = varintes;
        });
        handleModal("varient-delete", false, null);
      } else {
        errorMessage(res.message);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const handleDefaultVariant = async (id) => {
    try {
      const res = await updateDefaultVariant(id);
      if (res.success) {
        successMessage("Default Variant Updated Successfully");
        setState((draft) => {
          draft.basicDetails.details.variants =
            state.basicDetails.details.variants.map((data) => {
              return { ...data, default_variant: data.id === id ? 1 : 0 };
            });
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const HandleProductsPriceLevel = async (id) => {
    try {
      const res = await getProductPriceLevel(id);
      if (res.success) {
        setState((draft) => {
          draft.priceLevel.dataList = [
            ...res.data.map((data) => {
              return {
                ...data,
                special_price_end_date: data.special_price_end_date
                  ? moment(data.special_price_end_date)
                    .utc()
                    .format("YYYY-MM-DD HH:mm:ss")
                  : null,
                special_price_start_date: data.special_price_start_date
                  ? moment(data.special_price_start_date)
                    .utc()
                    .format("YYYY-MM-DD HH:mm:ss")
                  : null,
                cost: data?.cost ? Number(data.cost) : "",
                cost_exclusive_gst: data?.cost_exclusive_gst
                  ? Number(data.cost_exclusive_gst)
                  : "",
                variants: [
                  ...data?.variants?.map((datas, i) => {
                    return {
                      ...datas,
                      id: datas?.id,
                      name: datas?.name,
                      cost_adjustment: datas?.cost_adjustment
                        ? Number(datas?.cost_adjustment)
                        : "",
                      price_adjustment: datas?.price_adjustment
                        ? Number(datas?.price_adjustment)
                        : "",
                      cost_adjustment_exclusive_gst:
                        datas?.cost_adjustment_exclusive_gst
                          ? Number(datas?.cost_adjustment_exclusive_gst)
                          : "",
                    };
                  }),
                ],
              };
            }),
          ];
          draft.priceLevel.staticDataList = [
            ...res.data.map((data) => {
              return {
                ...data,
                special_price_end_date: data.special_price_end_date
                  ? moment(data.special_price_end_date)
                    .utc()
                    .format("YYYY-MM-DD HH:mm:ss")
                  : null,
                special_price_start_date: data.special_price_start_date
                  ? moment(data.special_price_start_date)
                    .utc()
                    .format("YYYY-MM-DD HH:mm:ss")
                  : null,
              };
            }),
          ];
          draft.priceLevel.isLoading = false;
        });
      }
    } catch (err) {
      setState((draft) => {
        draft.priceLevel.isLoading = false;
      });
    }
  };

  const HandlePriceLevelChange = (e, index, type = "", vi) => {
    const name = e?.target?.name;
    const value = e?.target?.value;
    const tax = basicDetails.details?.tax_group?.tax_rate;
    const taxRate = (Number(value) * tax) / 100;
    if (name === "cost_exclusive_gst") {
      setState((draft) => {
        draft.priceLevel.dataList = [
          ...draft.priceLevel.dataList.map((v, i) => {
            return i === index
              ? {
                ...v,
                [name]: value,
                cost: value
                  ? !isNaN((Number(value) + taxRate)?.toFixed(3))
                    ? (Number(value) + taxRate)?.toFixed(3)
                    : ""
                  : "",
              }
              : v;
          }),
        ];
      });
    }
    if (name === "cost") {
      setState((draft) => {
        draft.priceLevel.dataList = [
          ...draft.priceLevel.dataList.map((v, i) => {
            return i === index
              ? {
                ...v,
                [name]: value,
                cost_exclusive_gst: value
                  ? !isNaN((Number(value) - taxRate)?.toFixed(3))
                    ? (Number(value) - taxRate)?.toFixed(3)
                    : ""
                  : "",
              }
              : v;
          }),
        ];
      });
    }
    if (type === "variant") {
      if (name === "cost_adjustment_exclusive_gst") {
        setState((draft) => {
          draft.priceLevel.dataList = [
            ...draft.priceLevel.dataList.map((v, i) => {
              return i === index
                ? {
                  ...v,
                  variants: [
                    ...v.variants.map((v, i) => {
                      return i === vi
                        ? {
                          ...v,
                          [name]: value,
                          cost_adjustment: value
                            ? !isNaN((Number(value) + taxRate)?.toFixed(3))
                              ? (Number(value) + taxRate)?.toFixed(3)
                              : ""
                            : "",
                        }
                        : v;
                    }),
                  ],
                }
                : v;
            }),
          ];
        });
      } else if (name === "cost_adjustment") {
        setState((draft) => {
          draft.priceLevel.dataList = [
            ...draft.priceLevel.dataList.map((v, i) => {
              return i === index
                ? {
                  ...v,
                  variants: [
                    ...v.variants.map((v, i) => {
                      return i === vi
                        ? {
                          ...v,
                          [name]: value,
                          cost_adjustment_exclusive_gst: value
                            ? !isNaN((Number(value) - taxRate)?.toFixed(3))
                              ? (Number(value) - taxRate)?.toFixed(3)
                              : ""
                            : "",
                        }
                        : v;
                    }),
                  ],
                }
                : v;
            }),
          ];
        });
      } else {
        setState((draft) => {
          draft.priceLevel.dataList = [
            ...draft.priceLevel.dataList.map((v, i) => {
              return i === index
                ? {
                  ...v,
                  variants: [
                    ...v.variants.map((v, i) => {
                      return i === vi ? { ...v, [name]: value } : v;
                    }),
                  ],
                }
                : v;
            }),
          ];
        });
      }
    } else {
      setState((draft) => {
        draft.priceLevel.dataList = [
          ...draft.priceLevel.dataList.map((v, i) => {
            return i === index ? { ...v, [name]: value } : v;
          }),
        ];
      });
    }
  };

  const handleUpdateProductPricelevel = async (index, type, value) => {
    if (type === "status") {
      let data;
      if (!state.priceLevel.dataList[index].special_price) {
        data = {
          ...state.priceLevel.dataList[index],
          special_price_start_date: null,
          special_price_end_date: null,
          status: value,
        };
      } else {
        data = {
          ...state.priceLevel.dataList[index],
          special_price_start_date: state.priceLevel.dataList[index]
            .special_price_start_date
            ? moment(state.priceLevel.dataList[index].special_price_start_date)
              .startOf("day")
              .format("YYYY-MM-DD HH:mm:ss")
            : null,
          special_price_end_date: state.priceLevel.dataList[index]
            .special_price_end_date
            ? moment(state.priceLevel.dataList[index].special_price_end_date)
              .endOf("day")
              .format("YYYY-MM-DD HH:mm:ss")
            : null,
          status: value,
        };
      }

      setState((draft) => {
        draft.priceLevel.dataList = [
          ...draft.priceLevel.dataList.map((v, i) => {
            return i === index ? { ...data } : v;
          }),
        ];
      });
      setState((draft) => {
        draft.priceLevel.isSaveButton = true;
      });
      try {
        const res = await updateProductPriceLevel(isDetailsId, data);
        if (res.success) {
          successMessage(res.message);
          setState((draft) => {
            draft.priceLevel.isSaveButton = false;
            draft.priceLevel.staticDataList = [
              ...draft.priceLevel.dataList.map((v, i) => {
                return i === index ? { ...data } : v;
              }),
            ];
          });
        }
      } catch (err) {
        setState((draft) => {
          draft.priceLevel.isSaveButton = false;
        });
        errorMessage(err.response.data.message);
      }
    } else {
      setState((draft) => {
        draft.priceLevel.isSaveButton = true;
      });
      try {
        let data;
        if (!state.priceLevel.dataList[index].special_price) {
          data = {
            ...state.priceLevel.dataList[index],
            special_price_start_date: null,
            special_price_end_date: null,
            status: 1,
          };
        } else {
          data = {
            ...state.priceLevel.dataList[index],
            special_price_start_date: state.priceLevel.dataList[index]
              .special_price_start_date
              ? moment(
                state.priceLevel.dataList[index].special_price_start_date
              )
                .startOf("day")
                .format("YYYY-MM-DD HH:mm:ss")
              : null,
            special_price_end_date: state.priceLevel.dataList[index]
              .special_price_end_date
              ? moment(state.priceLevel.dataList[index].special_price_end_date)
                .endOf("day")
                .format("YYYY-MM-DD HH:mm:ss")
              : null,
            status: 1,
          };
        }
        setState((draft) => {
          draft.priceLevel.dataList = [
            ...draft.priceLevel.dataList.map((v, i) => {
              return i === index ? { ...data } : v;
            }),
          ];
        });
        const res = await updateProductPriceLevel(isDetailsId, data);
        if (res.success) {
          successMessage(res.message);
          setState((draft) => {
            draft.priceLevel.isSaveButton = false;
            draft.priceLevel.staticDataList = [
              ...draft.priceLevel.dataList.map((v, i) => {
                return i === index ? { ...data } : v;
              }),
            ];
          });
        }
      } catch (err) {
        setState((draft) => {
          draft.priceLevel.isSaveButton = false;
        });
        errorMessage(err.response.data.message);
      }
    }
  };

  //Bundle Group

  const HandleProductBundleGroup = async (id) => {
    setState((draft) => {
      draft.bundleGroup.isLoading = true;
      draft.bundleGroup.dataList = [];
    });
    if (state.basicDetails.details.variants.length > 0) {
      const variants = state.basicDetails.details?.variants;
      for (let i = 0; i < variants.length; i++) {
        try {
          const res = await getProductBundleGroup(variants[i].id);
          if (res.success) {
            setState((draft) => {
              draft.bundleGroup.dataList = [
                ...draft.bundleGroup.dataList,
                { ...variants[i], bundle: res.data },
              ];
            });
          }
        } catch (err) {
          errorMessage(err.response.data.message);
        }
      }
      setState((draft) => {
        draft.bundleGroup.isLoading = false;
      });
    } else {
      try {
        const res = await getProductBundleGroup(id);
        if (res.success) {
          setState((draft) => {
            draft.bundleGroup.dataList = [
              {
                id: id,
                name: "Bundle Group",
                bundle: res.data,
              },
            ];
            draft.bundleGroup.isLoading = false;
          });
        }
      } catch (err) {
        errorMessage(err.response.data.message);
        setState((draft) => {
          draft.bundleGroup.isLoading = false;
        });
      }
    }
  };

  const HandleFilterBundleGroup = (type, text) => {
    const alreadyBundleList = state.bundleGroup.dataList
      .find((val) => {
        return val.id === state.bundleGroup.varientId;
      })
      .bundle.map((val) => {
        return val.id;
      });
    const filteredBundleList = state.bundleGroup.allDataList.filter(
      (val) => !alreadyBundleList.includes(val.id)
    );
    if (type === "initial") {
      setState((draft) => {
        draft.bundleGroup.searchText = "";
        draft.bundleGroup.selectedItems = [];
        draft.bundleGroup.filteredList = filteredBundleList;
        draft.bundleGroup.dialogLoading = false;
        return draft;
      });
    } else {
      text = text.toLowerCase();
      setState((draft) => {
        draft.bundleGroup.searchText = text;
        draft.bundleGroup.filteredList = filteredBundleList.filter((val) => {
          return val.name.toString().toLowerCase().indexOf(text) > -1;
        });
        return draft;
      });
    }
  };

  useEffect(() => {
    if (state.bundleGroup.varientId) {
      setState((draft) => {
        draft.bundleGroup.dialogLoading = true;
      });
      HandleFilterBundleGroup("initial");
    }
  }, [state.bundleGroup.varientId]);

  const handleBundleProductSelect = (id) => {
    setState((draft) => {
      draft.bundleGroup.page = 2;
      draft.bundleGroup.selectedItems = [
        {
          id: id,
          min_choice: 1,
          max_choice: 1,
          selection_type: 2,
        },
      ];
      return draft;
    });
  };

  const handleAssignproductToBundle = async (type = "") => {
    const data = {
      bundle_groups: state.bundleGroup.selectedItems,
    };
    setState((draft) => {
      draft.bundleGroup.isBusy = true;
      return draft;
    });
    try {
      const res = await assignBundleToProduct(
        data,
        type === "edit"
          ? state.bundleGroup.selectedId
          : state.bundleGroup.varientId
      );
      if (res.success === true) {
        successMessage(res.message);

        setState((draft) => {
          draft.bundleGroup.selectedItems = [];
          draft.bundleGroup.selectedId = null;
          draft.bundleGroup.isBusy = false;
          return draft;
        });
        HandleProductBundleGroup(isDetailsId);
        handleModal("product-bundle-add", false, null);
        handleModal("product-bundle-edit", false, null, null);
      }
    } catch (error) {
      setState((draft) => {
        draft.bundleGroup.isBusy = false;
        return draft;
      });
      errorMessage(error.response.data.message);
    }
  };

  const handleDeleteProductFromBundle = async () => {
    try {
      const res = await deleteBundleFromProduct(
        state.bundleGroup.productId,
        state.bundleGroup.selectedId
      );
      if (res.success) {
        successMessage("Bundle group(s) removed");
        setState((draft) => {
          draft.bundleGroup.dataList = [
            ...draft.bundleGroup.dataList.map((val) => {
              return val.id === state.bundleGroup.productId
                ? {
                  ...val,
                  bundle: val.bundle.filter(
                    (item) => item.id !== state.bundleGroup.selectedId
                  ),
                }
                : val;
            }),
          ];
        });
        handleModal("product-bundle-delete", false, null, null);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const handleChangeBundleInput = (event, id) => {
    const { name, value } = event.target;

    setState((draft) => {
      draft.bundleGroup.selectedItems = [
        ...draft.bundleGroup.selectedItems.map((data) =>
          data.id === id ? { ...data, [name]: value } : data
        ),
      ];
    });
  };

  const onAddBarcode = (e) => {
    setState((draft) => {
      draft.barcode.details = [
        ...draft.barcode.details,
        {
          id: "",
          barcode: "",
          type: "",
        },
      ];
      draft.barcode.isDisable = [
        ...draft.barcode.isDisable,
        draft.barcode.details.length - 1,
      ];
    });
  };

  const getBarcodeListData = async (id) => {
    try {
      setState((draft) => {
        draft.isBusy = true;
      });
      const res = await getProductBarcode(id);
      if (res.data) {
        setState((draft) => {
          draft.barcode.details =
            res.data?.length > 0
              ? res.data
              : [
                {
                  id: "",
                  barcode: "",
                  type: "",
                },
              ];
          draft.isBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };

  const handleOnChange = (e, index, name) => {
    const { label, value } = e;
    setState((draft) => {
      draft.barcode.details = [
        ...draft.barcode.details.map((data, i) =>
          i === index ? { ...data, [name]: value } : data
        ),
      ];
    });
  };

  const onChange = (e, index) => {
    const { value, name } = e.target;
    setState((draft) => {
      draft.barcode.details = [
        ...draft.barcode.details.map((data, i) =>
          i === index ? { ...data, [name]: value } : data
        ),
      ];
    });
  };

  const handleDelete = async (index) => {
    try {
      const res = await deleteBarcode(
        isDetailsId,
        state.barcode.details[index].id
      );
      if (res.success) {
        successMessage("Deleted Successfully");
        setState((draft) => {
          draft.barcode.details = draft.barcode.details.filter(
            (item) => item.id !== state.barcode.details[index].id
          );
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const onDeleteIndex = (index, data) => {
    setState((draft) => {
      draft.barcode.details = draft.barcode.details.filter(
        (item, i) => i !== index
      );
    });
  };

  const onSaveBarcode = async (index) => {
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      const res = await createBarcode(
        isDetailsId,
        state.barcode.details[index]
      );
      if (res.success) {
        successMessage("Created Successfully");
        setState((draft) => {
          draft.isSaveButtonBusy = false;
          draft.barcode.isDisable = [];
        });
        getBarcodeListData(isDetailsId);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };

  const onUpdateBarcode = async (index, data) => {
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      const res = await editBarcode(
        isDetailsId,
        data.id,
        state.barcode.details[index]
      );
      if (res.success) {
        successMessage("Updated Successfully");
        setState((draft) => {
          draft.isSaveButtonBusy = false;
          draft.isDisable = false;
          draft.barcode.isDisable = [];
        });
        getBarcodeListData(isDetailsId);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
        draft.isDisable = false;
      });
    }
  };

  const handleSelectAll = (checked) => {
    if (checked) {
      setState((draft) => {
        draft.stores.selectedItems = [
          ...draft.stores.filteredList.map((item) => item.id),
        ];
      });
    } else {
      setState((draft) => {
        draft.stores.selectedItems = [];
      });
    }
  };

  const onProductCopyClick = (productName = "", id = null) => {
    setState((draft) => {
      draft.copyProduct.id = id;
      draft.copyProduct.productName = productName;
      draft.copyProduct.isOpen = !draft.copyProduct.isOpen;
      draft.copyProduct.copyOptions = [];
    });
  };

  const productCloneOptions = useMemo(
    () =>
      globalData?.clone?.products.map((item) => ({
        id: item.value,
        name: item.label,
      })) || [][globalData?.clone?.products]
  );

  const onCloneOptionsSelect = (id) => {
    const isSelected = state.copyProduct.copyOptions.find(
      (item) => item === id
    );
    if (isSelected) {
      setState((draft) => {
        draft.copyProduct.copyOptions = draft.copyProduct.copyOptions.filter(
          (item) => item !== id
        );
      });
    } else {
      setState((draft) => {
        draft.copyProduct.copyOptions = [...draft.copyProduct.copyOptions, id];
      });
    }
  };

  const handleSelectAllCopyOptions = (checked) => {
    if (checked) {
      setState((draft) => {
        draft.copyProduct.copyOptions = [
          ...productCloneOptions.map((item) => item.id),
        ];
      });
    } else {
      setState((draft) => {
        draft.copyProduct.copyOptions = [];
      });
    }
  };

  const onCloneSubmit = async () => {
    const payload = {
      contents: state.copyProduct.copyOptions,
    };
    const { id } = state.copyProduct;
    try {
      setState((draft) => {
        draft.copyProduct.isBusy = true;
      });
      const res = await copyProduct(payload, id);
      if (res.success) {
        setState((draft) => {
          draft.copyProduct.isBusy = false;
        });
        navigate(`/products/edit/${res.data.id}`);
      }
    } catch (err) {
      setState((draft) => {
        draft.copyProduct.isBusy = false;
      });
    }
  };

  const onEditBarcode = (index, data) => {
    setState((draft) => {
      draft.barcode.isDisable = [...draft.barcode.isDisable, index];
    });
  };

  // Promotions

  const HandleProductsCrossSelling = async (id) => {
    try {
      const res = await getProductCrossSelling(id);
      if (res.success) {
        setState((draft) => {
          draft.promotions.crossSelling.dataList = res.data;
          draft.promotions.crossSelling.isLoading = false;
        });
      }
    } catch (err) {
      setState((draft) => {
        draft.promotions.crossSelling.isLoading = false;
      });
    }
  };

  const HandleProductsSimilarProducts = async (id) => {
    try {
      const res = await getProductSimilarProducts(id);
      if (res.success) {
        setState((draft) => {
          draft.promotions.similarProducts.dataList = res.data;
          draft.promotions.similarProducts.isLoading = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.promotions.similarProducts.isLoading = false;
      });
    }
  };

  useEffect(() => {
    setState((draft) => {
      draft.promotions.crossSelling.alreadyInList =
        state.promotions.crossSelling.dataList.map((v) => v.id);
    });
  }, [state.promotions.crossSelling.dataList]);

  useEffect(() => {
    setState((draft) => {
      draft.promotions.similarProducts.alreadyInList =
        state.promotions.similarProducts.dataList.map((v) => v.id);
    });
  }, [state.promotions.similarProducts.dataList]);

  useEffect(() => {
    if (
      !state.promotions.crossSelling.isLoading &&
      !state.promotions.similarProducts.isLoading
    ) {
      setState((draft) => {
        draft.promotions.isLoading = false;
      });
    } else {
      setState((draft) => {
        draft.promotions.isLoading = true;
      });
    }
  }, [
    state.promotions.crossSelling.isLoading,
    state.promotions.similarProducts.isLoading,
  ]);

  const handleDeleteCrossSelling = async () => {
    try {
      const res = await deleteCrossSellingProduct(
        isDetailsId,
        state.promotions.crossSelling.selectedId
      );
      if (res.success) {
        successMessage("Cross selling product removed");
        setState((draft) => {
          draft.promotions.crossSelling.dataList =
            draft.promotions.crossSelling.dataList.filter(
              (item) => item.id !== state.promotions.crossSelling.selectedId
            );
        });
        if (
          state.promotions.crossSelling.dataList.length === 1 &&
          state.basicDetails.details.has_cross_selling
        ) {
          handleStatusCrossSelling();
        }
        handleModal("delete-crossSelling", false, null);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const handleStatusCrossSelling = async () => {
    try {
      const res = await changeStatusCrossSellingProduct(isDetailsId);
      if (res.success) {
        successMessage("Cross selling product status changed succesfully");
        setState((draft) => {
          draft.basicDetails.details = {
            ...draft.basicDetails.details,
            has_cross_selling:
              draft.basicDetails.details.has_cross_selling === 1 ? 0 : 1,
          };
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const handleStatusSimilarProducts = async () => {
    try {
      const res = await changeStatusSimilarProducts(isDetailsId);
      if (res.success) {
        successMessage("Similar product status changed succesfully");
        setState((draft) => {
          draft.basicDetails.details = {
            ...draft.basicDetails.details,
            has_similar_product:
              draft.basicDetails.details.has_similar_product === 1 ? 0 : 1,
          };
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const handleDeleteSimilarProducts = async () => {
    try {
      const res = await deleteCrossSimilarProducts(
        isDetailsId,
        state.promotions.similarProducts.selectedId
      );
      if (res.success) {
        successMessage("Similar product removed");
        setState((draft) => {
          draft.promotions.similarProducts.dataList =
            draft.promotions.similarProducts.dataList.filter(
              (item) => item.id !== state.promotions.similarProducts.selectedId
            );
        });
        if (
          state.promotions.similarProducts.dataList.length === 1 &&
          state.basicDetails.details.has_similar_product
        ) {
          handleStatusSimilarProducts();
        }
        handleModal("delete-similarProducts", false, null);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const handlePromotionsSelect = (id) => {
    const isSelcted = state.promotions.selectedItems.includes(id);
    if (isSelcted) {
      setState((draft) => {
        draft.promotions.selectedItems = draft.promotions.selectedItems.filter(
          (val) => val !== id
        );
      });
    } else {
      setState((draft) => {
        draft.promotions.selectedItems = [
          ...draft.promotions.selectedItems,
          id,
        ];
        return draft;
      });
    }
  };

  const handleAssignProductToPromotions = async () => {
    setState((draft) => {
      draft.promotions.isSaveButton = true;
    });
    if (state.promotions.type === 1) {
      const data = {
        cross_selling_product_id: state.promotions.selectedItems,
      };

      try {
        const res = await assignProductToCrossSelling(data, isDetailsId);
        if (res.success === true) {
          successMessage(res.message);
          setState((draft) => {
            draft.promotions.selectedItems = [];
            draft.promotions.isSaveButton = false;
            return draft;
          });
          handleModal("crossSelling-add", false, null);
          HandleProductsCrossSelling(isDetailsId);
        }
      } catch (error) {
        errorMessage(error.response.data.message);
        setState((draft) => {
          draft.promotions.isSaveButton = false;
        });
      }
    } else {
      const data = {
        similar_product_id: state.promotions.selectedItems,
      };

      try {
        const res = await assignProductToSimilarProducts(data, isDetailsId);
        if (res.success === true) {
          successMessage(res.message);
          setState((draft) => {
            draft.promotions.selectedItems = [];
            draft.promotions.isSaveButton = false;
            return draft;
          });
          handleModal("crossSelling-add", false, null);
          HandleProductsSimilarProducts(isDetailsId);
        }
      } catch (error) {
        errorMessage(error.response.data.message);
        setState((draft) => {
          draft.promotions.isSaveButton = false;
        });
      }
    }
  };

  const handleColor = (e) => {
    setState((draft) => {
      draft.updateDetails.updateData.colour = e === null ? e : e?.value;
    });
  };

  const updateIsloading = () => {
    setState((draft) => {
      draft.basicDetails.isLoading = true;
    });
  };

  const getSeoDatails = async (id) => {
    try {
      setState((draft) => {
        draft.isBusy = true;
      });
      const res = await getProductSeo(id);
      if (res.data) {
        setState((draft) => {
          draft.seoDetails = res.data;
          draft.isBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isBusy = false;
      });
    }
  };

  useEffect(() => {
    if (currentTab === "7") {
      getSeoDatails(isDetailsId);
    }
  }, [currentTab]);

  const onUpdateProductSeo = async (id) => {
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      const res = await updateProductSeo(id, state.seoDetails);
      if (res.success) {
        successMessage(res.message);
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
        navigate(`/products/details/${id}`);
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };

  const onValueChange = (e) => {
    const { value, name } = e.target;
    setState((draft) => {
      draft.seoDetails[name] = value;
    });
  };

  //======================Add Loyalty cards and account=============================

  // Simple Accounts API
  const getAccountLists = async () => {
    try {
      const response = await getAccountsApi();
      const { success, data } = response;
      if (success) {
        setState((draft) => {
          draft.accountsList = {
            ...draft.accountsList,
            listData: data,
          };
        });
      }
    } catch (error) {
      errorMessage(error.response.data.message);
    }
  };

  // Function to handle loyalty card checkbox changes
  const handleLoyaltyCheckboxChanges = (e, details) => {
    const { loyalty_card_type_id } = state.updateDetails.updateData;
    setState((draft) => {
      const isExisted = isLoyaltyOptionInclude(details);

      if (isExisted) {
        draft.updateDetails.updateData.loyalty_card_type_id =
          loyalty_card_type_id.filter((id) => id !== details.value);
      } else {
        draft.updateDetails.updateData.loyalty_card_type_id = [
          ...draft.updateDetails.updateData.loyalty_card_type_id,
          details.value,
        ];
      }
    });
  };

  // find loyalty card details
  const isLoyaltyOptionInclude = (details) => {
    const { loyalty_card_type_id } = state.updateDetails.updateData;
    const isExisted = loyalty_card_type_id.find((id) => id === details.value);
    return Boolean(isExisted);
  };

  // useEffect to fetch account-list on create mode
  React.useEffect(() => {
    if (!isEditMode && isCreate) {
      getAccountLists();
    }
  }, []);

  // Reset the default tab from location
  React.useLayoutEffect(() => {
    if (location.search.includes("?reviews")) {
      setCurentTab("productDetails", "15");
    }
  }, [location.search]);
  return {
    state,
    globalData,
    currentTab,
    IsEditable,
    isCreate,
    categoriesList,
    taxGroupList,
    isEditMode,
    accountsList: state.accountsList,
    setCurentTab,
    getProducts,
    HandleDetailsInfoChange,
    onsubmitDetailsInfo,
    handleNestedInput,
    handleAvailabilityDate,
    onSubmitAvailability,
    HandleFilterAG,
    handleAGSelect,
    handleAssignAGToProduct,
    handleModal,
    handleDeleteAGFromProduct,
    HandleFilterOG,
    handleOGSelect,
    handleAssignOGToProduct,
    handleDeleteOGFromProduct,
    HandleFilterStore,
    handleStoreSelect,
    handleAssignStoresToProduct,
    handleDeleteStoreFromProduct,
    onUpdateProduct,
    onSaveProduct,
    handleProductInputChange,
    handleDeleteProduct,
    handleVarientNestedInput,
    HandleVarientChange,
    handleCreateAndUpdatevarient,
    handleDeleteVariant,
    handleDefaultVariant,
    HandlePriceLevelChange,
    handleUpdateProductPricelevel,
    handleBundleProductSelect,
    HandleFilterBundleGroup,
    handleAssignproductToBundle,
    handleDeleteProductFromBundle,
    onAddBarcode,
    handleDelete,
    handleSelectAll,
    onCloneSubmit,
    handleSelectAllCopyOptions,
    onCloneOptionsSelect,
    productCloneOptions,
    onProductCopyClick,
    handleOnChange,
    onChange,
    onSaveBarcode,
    onUpdateBarcode,
    onDeleteIndex,
    products: state.productsList.listData,
    onEditBarcode,
    handleDeleteCrossSelling,
    handleDeleteSimilarProducts,
    handleStatusCrossSelling,
    handlePromotionsSelect,
    handleAssignProductToPromotions,
    handleStatusSimilarProducts,
    validateDate,
    isAllValid,
    isNotValidated,
    handleChangeBundleInput,
    handleChangeOGInput,
    handleColor,
    updateIsloading,
    onUpdateProductSeo,
    onValueChange,
    handleLoyaltyCheckboxChanges,
    isLoyaltyOptionInclude,
  };
};
