import React, { useEffect, useContext } from "react";
import { useImmer } from "use-immer";
import { successMessage, errorMessage } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../store/AppScopeProvider";
import {
  useActivityLog,
  useDropdownApis,
  usePermission,
} from "../../../shared";
import { useDebouncedCallback } from "use-debounce";
import qs from "qs";
import {
  createEndofDays,
  editEndofDay,
  getEndofDay,
  getEndofDayDetails,
  getEndofDaySummary,
  getEODSummary,
  getPairedTerminalListToEndofday,
  getTerminalListToEndofday,
} from "../api/EndofDayapi";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../../shared/hooks/useAuth";
import { TableStateContext } from "../../../store";

const initialData = {
  terminal_id: "",
  store_id: "",
  cashless_till: 1,
  float_total: null,
  cheque_total: "",
  sale_date: "",
  cash_total: "",
  status: "",
  card_total: "",
  remarks: "",
  simple: false,
  eod_template_name: "",
  denominations: [
    {
      id: 1,
      eod_id: 1,
      denomination_id: 1,
      denomination_label: "5¢",
      denomination_cash: 0.05,
      entry_count: "",
      float_count: "",
      modification: "",
      bank: "",
      max: 80,
      min: 20,
    },
    {
      id: 2,
      eod_id: 1,
      denomination_id: 2,
      denomination_label: "10¢",
      denomination_cash: 0.1,
      entry_count: "",
      float_count: "",
      modification: "",
      bank: "",
      max: 80,
      min: 20,
    },
    {
      id: 3,
      eod_id: 1,
      denomination_id: 3,
      denomination_label: "20¢",
      denomination_cash: 0.2,
      entry_count: "",
      float_count: "",
      modification: "",
      bank: "",
      max: 40,
      min: 20,
    },
    {
      id: 4,
      eod_id: 1,
      denomination_id: 4,
      denomination_label: "50¢",
      denomination_cash: 0.5,
      entry_count: "",
      float_count: "",
      modification: "",
      bank: "",
      max: 40,
      min: 10,
    },
    {
      id: 5,
      eod_id: 1,
      denomination_id: 5,
      denomination_label: "$1",
      denomination_cash: 1,
      entry_count: "",
      float_count: "",
      modification: "",
      bank: "",
      max: 40,
      min: 10,
    },
    {
      id: 6,
      eod_id: 1,
      denomination_id: 6,
      denomination_label: "$2",
      denomination_cash: 2,
      entry_count: "",
      float_count: "",
      modification: "",
      bank: "",
      max: 35,
      min: 10,
    },
    {
      id: 7,
      eod_id: 1,
      denomination_id: 7,
      denomination_label: "$5",
      denomination_cash: 5,
      entry_count: "",
      float_count: "",
      modification: "",
      bank: "",
      max: 15,
    },
    {
      id: 8,
      eod_id: 1,
      denomination_id: 8,
      denomination_label: "$10",
      denomination_cash: 10,
      entry_count: "",
      float_count: "",
      modification: "",
      bank: "",
      max: 10,
    },
    {
      id: 9,
      eod_id: 1,
      denomination_id: 9,
      denomination_label: "$20",
      denomination_cash: 20,
      entry_count: "",
      float_count: "",
      modification: "",
      bank: "",
      max: 10,
    },
    {
      id: 10,
      eod_id: 1,
      denomination_id: 10,
      denomination_label: "$50",
      denomination_cash: 50,
      entry_count: "",
      float_count: "",
      modification: "",
      bank: "",
    },
    {
      id: 11,
      eod_id: 1,
      denomination_id: 11,
      denomination_label: "$100",
      denomination_cash: 100,
      entry_count: "",
      float_count: "",
      modification: "",
      bank: "",
    },
  ],
};

export const useEndofDay = ({
  isEndofDay,
  EndofdayId,
  depositslipData,
  isCreate,
  isDetail,
}) => {
  const { pathname } = useLocation();
  const { addActivityLog } = useActivityLog();
  const isStoreAdmin = pathname.includes("store-admin");
  const navigate = useNavigate();
  const { appState } = useContext(AppContext);
  const { globalData } = appState;
  const { auth } = useAuth();
  const { storeList } = useDropdownApis({
    isStoreList: (isStoreAdmin ? false : true) && !isDetail,
  });
  const {
    filterState: {
      params: { eod },
    },
    filterStateDispatch,
  } = useContext(TableStateContext);

  const isCreateVisible = usePermission("end-of-day-create");
  const isEditable = usePermission("end-of-day-modify");
  const isVisible = usePermission("end-of-day-view");
  const isSummaryVisble = usePermission("end-of-day-summary-view");
  const isForceEdit = usePermission("end-of-day-force-edit");
  const isDepositSlipVisible = usePermission("deposit-slip-view");
  const PAGE_NAME = "eod";
  const [state, setState] = useImmer({
    details: {},
    summaryDetails: {},
    showEodTable: null,
    isBusy: false,
    isLoading: false,
    isDisabled: true,
    endofday: [],
    isSummary: false,
    isApplyButtonBusy: false,
    isInit: true,
    endofdaySummary: {
      card_total: 0,
      client_account_total: 0,
      gross_sales: 0,
      system_cash: 0,
      total_tax: 0,
      loyalty_total: 0,
      gift_card_total: 0,
      round_off_total: 0,
      variance_status: false,
      eod_variance: 0,
    },
    eod: {},
    initialLoad: true,
    isSaveButtonBusy: false,
    filterApplied: {
      isSearching: false,
      all: [],
      searchText: "",
      stores: [],
      terminals: [],
      status: "",
      date: {},
    },
    store_id: [],
    pairedterminallist: [],
    terminalData: [],
    varianceError: false,
    isSubmitted: false,
    isCalculated: false,
    filterData: {
      terminal_id: null,
      store_id: null,
      sale_date: new Date(),
    },
    data: initialData,
    date: [
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ],
    showDate: [],
    pagination: {
      pageIndex: 1,
      pageSize: 50,
      pageCount: 0,
      total: 0,
      hasMorePages: true,
      lastPage: 1,
    },
    disableApplyButton: true,
    sortBy: [],
    is72HourPassed: false,
  });

  useEffect(() => {
    if (depositslipData && state.isInit) {
      setState((draft) => {
        draft.filterData.store_id = depositslipData?.store_id;
        draft.filterData.terminal_id = depositslipData?.terminal_id;
        draft.filterData.sale_date = depositslipData?.date;
        draft.isApplyButtonBusy = false;
        draft.isInit = false;
      });
    }
  }, [depositslipData]);


  const Cashtotal = state.data.denominations.reduce((acc, item) => {
    return (
      acc +
      (Number(item.modification) + Number(item.bank)) * item.denomination_cash
    );
  }, 0);

  const Floattotal = state.data.denominations.reduce((acc, item) => {
    return acc + item.float_count * item.denomination_cash;
  }, 0);

  const variance =
    Number(Cashtotal) -
    Number(
      state?.endofdaySummary?.system_cash
        ? state?.endofdaySummary?.system_cash
        : 0
    );

  const debounced = useDebouncedCallback((value) => {
    const { status, stores, terminals, date } = state.filterApplied;
    const { pagination, sortBy } = state;
    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: { filterApplied: state.filterApplied },
    });
    getEndofDayList({
      searchText: value,
      stores,
      terminals,
      status,
      date,
      pagination: {
        pageIndex: 1,
        pageSize: pagination.pageSize,
      },
      sortBy,
    });
  }, 1000);

  useEffect(() => {
    if (isEndofDay) {
      const { searchText, status, stores, terminals, date } = eod.filterApplied;
      const { pagination, sortBy } = eod;
      setState((draft) => {
        draft.filterApplied = eod.filterApplied;
        draft.pagination = pagination;
        draft.sortBy = sortBy;
      });
      getEndofDayList({
        searchText,
        stores,
        terminals,
        status,
        date,
        pagination: {
          pageIndex: pagination.pageIndex,
          pageSize: pagination.pageSize,
        },
        sortBy,
      });
    }
  }, [isEndofDay]);
  const getEndofDayList = async ({
    stores = [],
    terminals = [],
    searchText = "",
    status = "",
    date = {},
    pagination,
    sortBy = [],
  }) => {
    let params = {};
    let IDs = stores.map((v) => {
      return v.value;
    });
    let terminal = terminals.map((v) => {
      return v.value;
    });
    if (searchText) {
      params = { ...params, search: searchText };
    }
    if (toString(status.value)) {
      params = { ...params, status: status.value };
    }
    if (IDs.length > 0) {
      params = { ...params, store_id: IDs };
    }
    if (terminal.length > 0) {
      params = { ...params, terminal_id: terminal };
    }
    if (sortBy.length > 0) {
      const sortByParams = sortBy.reduce(
        (obj, item) =>
          Object.assign(obj, { [item.id]: item.desc ? "desc" : "asc" }),
        {}
      );
      params = {
        ...params,
        sort: sortByParams,
      };
    }
    if (Object.keys(date).length) {
      params = {
        ...params,
        start_date: moment(date.value.startDate).format("YYYY-MM-DD"),
        end_date: moment(date.value.endDate).format("YYYY-MM-DD"),
      };
    }
    const query = {
      params,
      paramsSerializer: (params) => qs.stringify(params),
    };
    try {
      setState((draft) => {
        draft.isBusy = true;
        draft.initialLoad = true;
        draft.disableApplyButton = true;
      });
      const res = await getEndofDay(query, pagination);
      if (res.success) {
        setState((draft) => {
          draft.isBusy = false;
          draft.endofday = res.data.end_of_days;
          draft.pagination = res.data.pagination;
          draft.initialLoad = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isBusy = false;
        draft.initialLoad = false;
        draft.disableApplyButton = false;
      });
    }
  };

  const onChange = (e, type) => {
    const { value, name } = e.target;
    setState((draft) => {
      draft.data[name] = value;
    });
  };
  const onFilterChange = (e, type) => {
    const { value, name } = e.target;
    setState((draft) => {
      draft.filterData[name] = value;
      draft.isApplyButtonBusy = false;
    });
  };
  const handleFloatChange = (e, index, cash) => {
    const { name, value } = e.target;
    setState((draft) => {
      draft.data.denominations[index].denomination_id = name;
      draft.data.denominations[index].float_count = value;
      draft.data.denominations[index].float_total = value * cash;
    });
  };
  const handleCashChange = (e, index, cash) => {
    const { name, value } = e.target;
    setState((draft) => {
      draft.data.denominations[index].denomination_id = name;
      draft.data.denominations[index].cash_count = value;
      draft.data.denominations[index].cash_total = value * cash;
    });
  };
  const onSaveEndofDay = async () => {
    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      const payload = {
        ...state.data,
        cash_total: Cashtotal,
        sale_date: state.data.sale_date
          ? moment(state.data.sale_date).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD"),
        cheque_total:
          state.data.cheque_total === "" ? 0 : state.data.cheque_total,
        float_total: state.data.float_total === "" ? 0 : state.data.float_total,
        denominations: [
          ...state.data.denominations.map((data) => {
            return {
              ...data,
              cash_count: data.cash_count === "" ? 0 : data.cash_count,
              float_count: data.float_count === "" ? 0 : data.float_count,
            };
          }),
        ],
      };

      const res = await createEndofDays(payload);

      if (res.success) {
        const { id, store_name, terminal_name, sale_date } = res.data;

        const newVariance = { variance: variance?.toFixed(2) };

        const parameterData = {
          StoreName: store_name,
          TerminalName: terminal_name,
          EODdate: sale_date,
          CashVariance: variance?.toFixed(2),
        };
        const newData = res?.data;
        const newLogData = Object.keys(newData)
          .filter((key) => key !== "eod_variance")
          .reduce((obj, key) => {
            obj[key] = newData[key];
            return obj;
          }, {});

        const newActivitydata = Object.assign({}, newLogData, newVariance);

        addActivityLog({
          templateId: 1,
          parameters: parameterData,
          uniqueParameter: id,
          data: { newLogData: newActivitydata, oldLogData: {} },
        });

        successMessage("Created Successfully");

        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
        isStoreAdmin
          ? navigate(`/store-admin/end-of-day/details/${res.data.id}`)
          : navigate(`/end-of-day/details/${res.data.id}`);
      } else {
        errorMessage(res?.message);
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };

  const handleDateChange = (e) => {
    setState((draft) => {
      draft.filterData.sale_date = e;
      draft.isApplyButtonBusy = false;
    });
  };
  const handleDropdown = (e) => {
    setState((draft) => {
      draft.filterData.store_id = e?.value;
      draft.filterData.terminal_id = "";
    });
  };
  const getTeminalListDate = async (id = null) => {
    try {
      const selectedStoreId = id;
      const res = await getTerminalListToEndofday(selectedStoreId);

      if (res.success) {
        setState((draft) => {
          draft.terminalData = res.data;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };

  const getPairedTeminalListData = async (id = null) => {
    try {
      let params = {};
      const storeId = [id];

      if (id) {
        params = { ...params, store: storeId };
      }

      const query = {
        params,
        paramsSerializer: (params) => qs.stringify(params),
      };
      const res = await getPairedTerminalListToEndofday(query);

      if (res.success) {
        setState((draft) => {
          draft.pairedterminallist = res.data;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
    }
  };
  useEffect(() => {
    if (isEndofDay && isStoreAdmin && auth.data.selectedStoreId) {
      getPairedTeminalListData(auth.data.selectedStoreId);
    }
  }, [isEndofDay, auth.data.selectedStoreId, isStoreAdmin]);

  useEffect(() => {
    if (isEndofDay && !isStoreAdmin) {
      getPairedTeminalListData();
    }
  }, [isEndofDay, isStoreAdmin]);

  useEffect(() => {
    if (isStoreAdmin && auth.data.selectedStoreId && isCreate) {
      getTeminalListDate(auth.data.selectedStoreId);
    }
  }, [auth.data.selectedStoreId, isStoreAdmin, isCreate]);

  useEffect(() => {
    if (state.filterData.store_id && isCreate && !isStoreAdmin) {
      getTeminalListDate(state.filterData.store_id);
    }
  }, [isCreate, state.filterData.store_id, isStoreAdmin]);

  const handleEndofDayDetailsList = async () => {
    try {
      setState((draft) => {
        draft.isBusy = true;
      });
      const res = await getEndofDayDetails(EndofdayId);

      if (res.data) {
        const referenceTime = moment(res.data.created_at);
        const currentTime = moment();
        const hoursDifference = currentTime.diff(referenceTime, 'hours');

        setState((draft) => {
          draft.details = res.data;
          draft.is72HourPassed = hoursDifference >= 72
          draft.showEodTable = true;
          draft.isCalculated = true;
          draft.endofdaySummary.variance_status = res.data.variance_status;
          draft.endofdaySummary.eod_variance = Number(res.data.eod_variance);
          draft.filterData = {
            terminal_id: res.data.terminal_id,
            store_id: res.data.store_id,
            sale_date: res.data.sale_date,
          };
          draft.data = {
            ...res.data,
            denominations: [
              ...res?.data?.denominations.map((data, index) => {
                return {
                  ...data,
                  cash_count: data.cash_count === 0 ? "" : data.cash_count,
                  float_count: data.float_count === 0 ? "" : data.float_count,
                  min: state.data.denominations[index].min,
                  max: state.data.denominations[index].max,
                };
              }),
            ],
          };
          draft.display_name = res.data.display_name;
          draft.isBusy = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.httpStatusCodes.details = err.response.status;
        draft.isBusy = false;
      });
    }
  };
  useEffect(() => {
    if (EndofdayId) {
      handleEndofDayDetailsList(EndofdayId);
    }
  }, [EndofdayId]);

  const handleEndofDaySummary = async () => {
    try {
      const res = await getEndofDaySummary(EndofdayId);
      if (res.data) {
        setState((draft) => {
          draft.summaryDetails = res.data.variance;
          draft.endofdaySummary = { ...draft.endofdaySummary, ...res.data };
          draft.isSummary = true;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSummary = false;
      });
    }
  };

  useEffect(() => {
    if (EndofdayId && isSummaryVisble) {
      handleEndofDaySummary();
    }
  }, [EndofdayId, isSummaryVisble]);
  const handleDisabled = (e) => {
    setState((draft) => {
      draft.isDisabled = e;
    });
  };

  const onUpdateEndofDay = async (id) => {
    if (Number(Floattotal) === Number(state.data.float_total)) {
      try {
        setState((draft) => {
          draft.isSaveButtonBusy = true;
        });
        const payload = {
          ...state.data,
          cash_total: Cashtotal,
          cheque_total:
            state.data.cheque_total === "" ? 0 : state.data.cheque_total,
          float_total:
            state.data.float_total === "" ? 0 : state.data.float_total,
          denominations: [
            ...state.data.denominations.map((data) => {
              return {
                ...data,
                cash_count: data.cash_count === "" ? 0 : data.cash_count,
                float_count: data.float_count === "" ? 0 : data.float_count,
              };
            }),
          ],
        };

        const res = await editEndofDay(id, payload);

        if (res.success) {
          const { id, store_name, terminal_name, sale_date } = res.data;

          const newVariance = { variance: variance?.toFixed(2) };

          const parameterData = {
            StoreName: store_name,
            TerminalName: terminal_name,
            EODdate: sale_date,
            CashVariance: variance?.toFixed(2),
          };

          //New Data
          const newData = res?.data;
          const newLogData = Object.keys(newData)
            .filter((key) => key !== "eod_variance")
            .reduce((obj, key) => {
              obj[key] = newData[key];
              return obj;
            }, {});

          const newActivitydata = Object.assign({}, newLogData, newVariance);

          const oldVariance = { variance: state.summaryDetails?.toFixed(2) };

          //Old Data
          const oldData = state?.details;
          const oldLogdata = Object.keys(oldData)
            .filter((key) => key !== "denominations" && key !== "eod_variance")
            .reduce((obj, key) => {
              obj[key] = oldData[key];
              return obj;
            }, {});

          const oldActivitydata = Object.assign({}, oldLogdata, oldVariance);

          addActivityLog({
            templateId: 3,
            parameters: parameterData,
            uniqueParameter: id,
            data: { newLogData: newActivitydata, oldLogData: oldActivitydata },
          });

          successMessage("Updated Successfully");

          setState((draft) => {
            draft.isSaveButtonBusy = false;
          });
          handleEndofDayDetailsList();
          isStoreAdmin
            ? navigate(`/store-admin/end-of-day/details/${id}`)
            : navigate(`/end-of-day/details/${id}`);
        } else {
          errorMessage(res?.message);
          setState((draft) => {
            draft.isSaveButtonBusy = false;
          });
        }
      } catch (err) {
        errorMessage(err.response.data.message);
        setState((draft) => {
          draft.isSaveButtonBusy = false;
        });
      }
    } else {
      errorMessage("Float amount and float total is not equal");
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };
  const handleSummaryChange = (e, type) => {
    const { value, name } = e.target;
    setState((draft) => {
      draft.endofdaySummary[name] = value;
    });
  };
  const onChangeFilter = (e) => {
    const { value } = e.target;
    debounced(value);
    setState((draft) => {
      draft.filterApplied.searchText = value;
    });
    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: { filterApplied: { ...state.filterApplied, searchText: value } },
    });
  };
  const onSeachClear = () => {
    debounced("");
    setState((draft) => {
      draft.filterApplied.searchText = "";
    });
    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: { filterApplied: { ...state.filterApplied, searchText: "" } },
    });
  };
  const handleFilterClear = (type, value) => {
    const { pagination, sortBy, filterApplied } = state;
    if (type === "single") {
      if (value.type === "Store") {
        const filteredStores = state.filterApplied.stores.filter(
          (e) => e.value !== value.value
        );

        setState((draft) => {
          draft.filterApplied.stores = filteredStores;
        });
      } else if (value.type === "Terminals") {
        const filteredTerminals = state.filterApplied.terminals.filter(
          (e) => e.value !== value.value
        );
        setState((draft) => {
          draft.filterApplied.terminals = filteredTerminals;
        });
      } else if (value.type === "Status") {
        setState((draft) => {
          draft.filterApplied.status = "";
        });
      } else if (value.type === "Date") {
        setState((draft) => {
          draft.filterApplied.date = {};
          draft.showDate = [];
        });
      }
      setState((draft) => {
        draft.disableApplyButton = false;
      });
    } else {
      setState((draft) => {
        draft.filterApplied.all = [];
        draft.filterApplied.stores = [];
        draft.filterApplied.terminals = [];
        draft.filterApplied.status = "";
        draft.filterApplied.date = {};
        draft.showDate = [];
      });
      const initialValue = {
        all: [],
        searchText: filterApplied.searchText,
        stores: [],
        terminals: [],
        status: "",
        date: {},
      };
      filterStateDispatch({
        type: "CLEAR_FILTERS",
        page: PAGE_NAME,
        value: initialValue,
      });
      getEndofDayList({
        searchText: filterApplied.searchText,
        pagination: {
          pageIndex: 1,
          pageSize: pagination.pageSize,
        },
        sortBy,
      });
    }
  };
  const handleApplyFilter = () => {
    const { stores, terminals, searchText, status, date } = state.filterApplied;
    const { pagination, sortBy } = state;
    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: {
        filterApplied: state.filterApplied,
        pagination,
        sortBy,
      },
    });
    getEndofDayList({
      searchText,
      stores,
      terminals,
      status,
      date,
      pagination: {
        pageIndex: 1,
        pageSize: pagination.pageSize,
      },
      sortBy,
    });
  };
  const handleFilter = (e, value, type) => {
    if (type === "store") {
      setState((draft) => {
        draft.filterApplied.stores = value.map((v) => {
          return { ...v, type: "Store" };
        });
      });
    } else if (type === "terminals") {
      setState((draft) => {
        draft.filterApplied.terminals = value.map((v) => {
          return { ...v, type: "Terminals" };
        });
      });
    } else if (type === "status") {
      setState((draft) => {
        draft.filterApplied.status = {
          value: value.props.value,
          label: value.props.children,
          type: "Status",
        };
      });
    }
    setState((draft) => {
      draft.disableApplyButton = false;
    });
  };
  useEffect(() => {
    setState((draft) => {
      draft.filterApplied.all = [
        ...state.filterApplied.stores,
        ...state.filterApplied.terminals,
        state.filterApplied.status,
        state.filterApplied.date,
      ].filter((el) => Object.keys(el).length);
    });
  }, [
    state.filterApplied.date,
    state.filterApplied.stores,
    state.filterApplied.terminals,
    state.filterApplied.status,
  ]);

  const EODSummary = async () => {
    try {
      setState((draft) => {
        draft.isApplyButtonBusy = false;
        draft.isLoading = true;
        draft.data.terminal_id = draft.filterData.terminal_id;
        draft.data.sale_date = draft.filterData.sale_date;
        draft.data.store_id = draft.filterData.store_id;
      });
      const payload = {
        terminal_id: state.filterData.terminal_id,
        date: state.filterData.sale_date
          ? moment(state.filterData.sale_date).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD"),
      };

      const res = await getEODSummary(payload);
      if (res.success) {
        setState((draft) => {
          draft.showEodTable = res.data.eod === null ? true : false;
          draft.endofdaySummary = {
            system_cash: res.data.system_cash,
            card_total: res.data.card_total,
            client_account_total: res.data.client_account_total,
            gross_sales: res.data.gross_sales,
            total_tax: res.data.total_tax,
            loyalty_total: res.data.loyalty_total,
            gift_card_total: res.data.gift_card_total,
            round_off_total: res.data.round_off_total,
            variance_status: res.data.variance_status,
            eod_variance: Number(res.data.eod_variance),
          };
          draft.eod = res.data.eod === null ? {} : res.data.eod;
          draft.data = {
            ...initialData,
            float_total: res.data.float_amount,
            terminal_id: state.filterData.terminal_id,
            sale_date: state.filterData.sale_date,
            store_id: state.filterData.store_id,
            cashless_till: res.data.cashless_till,
            simple: res.data.simple,
            eod_template_name: res.data.eod_template_name,
          };
          draft.isSummary = true;
          draft.isApplyButtonBusy = true;
          draft.isLoading = false;
          draft.varianceError = false;
          draft.isSubmitted = false;
        });
      } else {
        setState((draft) => {
          draft.endofdaySummary = res.data;
          draft.isSummary = true;
          draft.isApplyButtonBusy = true;
          draft.isLoading = false;
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSummary = false;
        draft.isApplyButtonBusy = false;
        draft.isLoading = false;
      });
    }
  };

  const onChangeDate = (e) => {
    setState((draft) => {
      draft.date = e;
    });
  };
  const handleIsOpen = (e) => {
    setState((draft) => {
      draft.isCalenderOpen = e;
    });
  };

  const handleDateChangeApply = () => {
    setState((draft) => {
      draft.showDate = state.date;
      draft.isCalenderOpen = false;
      draft.filterApplied.date = {
        value: state.date[0],
        label: `${moment(state.date[0].startDate).format(
          "yyy-MM-DD"
        )} to ${moment(state.date[0].endDate).format("yyy-MM-DD")}`,
        type: "Date",
      };
      draft.disableApplyButton = false;
    });
  };

  const gotoPage = (page) => {
    const { searchText, stores, terminals, date, status } = state.filterApplied;
    const { sortBy, pagination } = state;

    getEndofDayList({
      searchText,
      stores,
      terminals,
      status,
      date,
      pagination: {
        pageIndex: page,
        pageSize: pagination.pageSize,
      },
      sortBy,
    });
    filterStateDispatch({
      type: "UPDATE_PAGE_INDEX",
      page: PAGE_NAME,
      value: page,
    });
    setState((draft) => {
      draft.pagination.pageIndex = page;
    });
  };

  const setPageSize = (e) => {
    const { searchText, stores, terminals, date, status } = state.filterApplied;
    const { sortBy, pagination } = state;

    getEndofDayList({
      searchText,
      stores,
      terminals,
      status,
      date,
      pagination: {
        pageIndex: 1,
        pageSize: +e.target.value,
      },
      sortBy,
    });
    filterStateDispatch({
      type: "UPDATE_PAGE_SIZE",
      page: PAGE_NAME,
      value: +e.target.value,
    });
    setState((draft) => {
      draft.pagination.pageSize = +e.target.value;
    });
  };

  const handleSort = (sortBy) => {
    const { searchText, stores, terminals, date, status } = state.filterApplied;
    const { pagination } = state;

    getEndofDayList({
      searchText,
      stores,
      terminals,
      status,
      date,
      pagination,
      sortBy,
    });
    filterStateDispatch({
      type: "UPDATE_SORT",
      page: PAGE_NAME,
      value: sortBy,
    });
    setState((draft) => {
      draft.sortBy = sortBy;
    });
  };

  return {
    state,
    setState,
    globalData,
    onChange,
    storeList,
    handleFloatChange,
    handleCashChange,
    onSaveEndofDay,
    handleDateChange,
    handleDropdown,
    handleDisabled,
    onUpdateEndofDay,
    handleSummaryChange,
    onChangeFilter,
    onSeachClear,
    handleFilterClear,
    handleApplyFilter,
    handleFilter,
    isCreateVisible,
    isEditable,
    isVisible,
    isSummaryVisble,
    isDepositSlipVisible,
    Cashtotal,
    Floattotal,
    isStoreAdmin,
    isForceEdit,
    EODSummary,
    onFilterChange,
    onChangeDate,
    handleIsOpen,
    handleDateChangeApply,
    variance,
    gotoPage,
    setPageSize,
    handleSort,
  };
};
