import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import moment from "moment";
import { useDropdownApis } from "../../../shared";
import { CONNECT_SITE_URL, errorMessage, infoMessage, SITE_URL, successMessage } from "../../../utils";
import { AppContext, AuthContext, TableStateContext } from "../../../store";
import { useImmer } from "use-immer";
import {
  downloadXeroMyobListApi,
  createXeroAndMyoMappingApi,
  getAccountSettingsListApi,
  XeroAndMyobHistoryListApi,
  getConnectionDetailListApi,
  XeroAndMyobSyncApi,
  updateXeroAndMyobSyncEmailApi,
} from "../api/XeroAndMyObApi";
import qs from "qs";
import { useDebouncedCallback } from "use-debounce";


export const useXeroAndMyOb = () => {
  // Need to remove the following code
  const [, forceUpdate] = React.useState();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const store_id = searchParams.get("store_id");
  const navigate = useNavigate();
  const { auth } = React.useContext(AuthContext);
  const { appState } = React.useContext(AppContext);
  const { xero, myob } = appState.currentTabs;
  const { globalData } = appState;
  const isStoreAdmin = pathname.includes("store-admin");
  const title = pathname.split("/")[isStoreAdmin ? 2 : 1];
  const payloadType = title.toLocaleLowerCase();
  const { selectedStoreId } = auth.data;
  const initialValidationState = {
    isValidated: false,
    start_date: null,
    end_date: null,
  };
  const PAGE_NAME = payloadType === "xero" ? "xeroHistory" : "myobHistory";
  const {
    filterState: {
      params: { xeroHistory, myobHistory },
    },
    initialState,
    filterStateDispatch,
  } = useContext(TableStateContext);

  const [state, setState] = useImmer({
    isBusy: false,
    isSaving: false,
    isSettingsUpdated: true,
    reportList: [],
    accountSettingsList: [],
    searchValue: "",
    data: {
      store_id: isStoreAdmin ? selectedStoreId : "",
      start_date: "",
      end_date: "",
    },
    history: [],
    pagination: {
      pageIndex: 1,
      pageSize: 50,
      pageCount: 0,
      total: 0,
      hasMorePages: true,
      lastPage: 1,
    },
    sortBy: [],
    validation: initialValidationState,
    filterApplied: {
      isSearching: false,
      searchText: "",
      syncDate: {},
      journalDate: {},
      status: null,
    },
    syncShowDate: [],
    syncDate: [],
    journalShowDate: [],
    journalDate: [],
    isSyncOpen: false,
    isJournalOpen: false,
    connectionDetails: {
      connection_status: 0
    },
    isLoading: true,
    storeName: isStoreAdmin ? auth.data.stores.find(v => v.id == selectedStoreId).name : "",
    isHistoryBusy: true,
    syncDates: new Date(),
    syncLoading: false,
    exportModal: false,
    moreModal: false,
    moreTexts: "",
    isSaveButtonBusy: false,
    emailInput: "",
  });

  // Breadcrumbs for navigation
  const breadcrumbsLink = [
    { name: "Dashboard", href: "/" },
    { name: title.toUpperCase(), href: "/" + title },
  ];

  const { storeList } = useDropdownApis({
    isStoreList: !isStoreAdmin,
  });

  const dateValidator = React.useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      validators: {
        endDate: {
          message: "The end date should greater than start date",
          rule: (value) => {
            const { startDate, endDate } = value;
            return moment(startDate, "YYYY-MM-DD").isSameOrBefore(
              moment(endDate, "YYYY-MM-DD")
            );
          },
        },
      },
    })
  );

  const settingsValidator = React.useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      validators: {
        accountCode: {
          message: "Account code is required !!",
          rule: (value) => {
            const { code, description, taxRate } = value;

            // Util function to check constraints
            const isError = () => {
              if (description || taxRate) {
                if (code?.trim()) {
                  return true;
                }
                return false;
              }
            };

            return isError();
          },
        },
      },
    })
  );

  // Validator function
  const validateDate = (reason, name) => {
    if (reason === "invalidDate") {
      setState((draft) => {
        draft.validation[name] = "Selected Date is not a valid";
      });
    } else if (reason === "minDate") {
      setState((draft) => {
        draft.validation[name] = "Selected Date is not a valid date";
      });
    } else {
      setState((draft) => {
        draft.validation[name] = null;
      });
    }
  };

  // Function to handle form submission
  const onGenerateReport = () => {
    if (
      dateValidator.current.allValid() &&
      !state.validation.end_date &&
      !state.validation.start_date
    ) {
      const { store_id, start_date, end_date } = state.data;

      const payload = {
        type: payloadType,
        store_id: isStoreAdmin ? selectedStoreId : store_id,
        start_date: moment(start_date).format("YYYY-MM-DD"),
        end_date: moment(end_date).format("YYYY-MM-DD"),
      };

      triggerLoading();

      handleDownloadXeroMyObList({ payload });
      handleExportModal(false)
    } else {
      dateValidator.current.showMessages();
      forceUpdate(1);
      setState((draft) => {
        draft.validation.isValidated = true;
      });
    }
  };

  // Function to handle input changes
  const handleInputChange = (event, type, SName) => {
    if (type === "store_id") {
      const { name, value } = event.target;
      navigate(`?store_id=${value}`, { replace: true });
      setState((draft) => {
        draft.data[name] = value;
        draft.storeName = SName
      });
    } else if (type === "start_date") {
      setState((draft) => {
        draft.data.start_date = event;
      });
    } else if (type === "end_date") {
      setState((draft) => {
        draft.data.end_date = event;
      });
    }
    else if (type === "syncDate") {
      setState((draft) => {
        draft.syncDates = event;
      });
    }
  };

  // Util function to trigger loading
  const triggerLoading = () => {
    setState((draft) => {
      draft.isBusy = !draft.isBusy;
    });
  };

  // Util Function to trigger save button loading
  const triggerSaveButtonLoading = () => {
    setState((draft) => {
      draft.isSaving = !draft.isSaving;
    });
  };

  // Function to handle download Xero and Myob list
  const handleDownloadXeroMyObList = async ({ payload }) => {
    try {
      const { success, message } = await downloadXeroMyobListApi(payload);

      if (success) {
        infoMessage(message);
      } else {
        errorMessage(message);
      }
      triggerLoading();
    } catch (err) {
      errorMessage(err.response.data.message);
      triggerLoading();
    }
  };

  // Function to handle fetch list
  const getAccountSettingsList = async ({ storeId }) => {
    try {
      const response = await getAccountSettingsListApi({ storeId });
      const { success, data, message } = response;

      if (success) {
        setState((draft) => {
          draft.accountSettingsList = data;
        });
      } else {
        errorMessage(message);
      }
      triggerLoading();
    } catch (error) {
      errorMessage(error.response.data.message);
      triggerLoading();
    }
  };

  // Function to handle create Xero and Myob mapping
  const handleCreateXeroAndMyob = async ({ payload }) => {
    try {
      const { success, message } = await createXeroAndMyoMappingApi(payload);

      // Cancel saveButton loading to false
      triggerSaveButtonLoading();

      if (success) {
        successMessage(message);
        setState((draft) => {
          draft.isSettingsUpdated = true;
        });
      } else {
        errorMessage(message);
      }
    } catch (err) {
      // Cancel saveButton loading to false
      triggerSaveButtonLoading();
      errorMessage(err.response.data.message);
    }
  };

  // Util function to reset the table-data
  const resetTable = () => {
    setState((draft) => {
      draft.searchValue = "";
    });
  };

  // Function to filter the mapping list based on search
  const filterAccountSettingsList = ({ value }) => {
    const convertText = (text, type = "lowerCase") => {
      return type === "lowerCase" ? text.toLowerCase() : text.toUpperCase();
    };

    const searchAndPlaceUnderParent = (value, data, parentPath = []) => {
      const { name, subData } = data;
      const match = convertText(name).includes(convertText(value));
      const currentPath = [...parentPath, { ...data, subData: [] }];

      let results = match ? [currentPath] : [];

      if (subData && subData.length > 0) {
        const subResults = subData.flatMap((subItem) =>
          searchAndPlaceUnderParent(value, subItem, currentPath)
        );
        results = results.concat(subResults);
      }

      return results;
    };

    const allResults = state.accountSettingsList.flatMap((item) =>
      searchAndPlaceUnderParent(value, item)
    );

    // Build the final filtered structure
    const filteredData = allResults.reduce((acc, item) => {
      const last = item[item.length - 1];
      const parentPath = item.slice(0, -1);

      // Find the parent in acc
      let parent = parentPath.reduce((nestedAcc, pathItem) => {
        const existingParent = nestedAcc.find(
          (accItem) => accItem.id === pathItem.id
        );
        if (existingParent) {
          return existingParent.subData;
        } else {
          const newParent = { ...pathItem, subData: [] };
          nestedAcc.push(newParent);
          return newParent.subData;
        }
      }, acc);

      // Add the last item under its parent
      parent.push(last);

      return acc;
    }, []);

    return filteredData;
  };

  // Function to handle table-search
  const handleSearch = (event) => {
    const { value } = event.target;
    setState((draft) => {
      draft.searchValue = value;
    });
  };

  // Function to handle search-clearing
  const handleSearchClear = () => resetTable();

  // Util function to handle mapping updates
  const handleRowChange = (event, row) => {
    const { value, name } = event.target;
    const objectName = payloadType === "xero" ? "xero_account" : "myob_account";

    // Recursive function to update accounts and their subData
    const makeUpdates = (accountsList) => {
      const response = accountsList.map((account) => {
        // Update the specific account with the new value
        if (account.id === row.id) {
          const updatedObject = {
            ...account,
            [objectName]: {
              account_id: account.id,
              ...account[objectName],
              [name]: value,
            },
          };
          return updatedObject;
        } else {
          // Check if there are subData and recursively update them
          const hasSubData = account.accounts > 0;
          if (hasSubData) {
            if (account[objectName] !== null) {
              return {
                ...account,
                [objectName]: {
                  account_id: account.id,
                  ...account[objectName],
                },
                subData: makeUpdates(account.subData),
              };
            } else {
              return {
                ...account,
                subData: makeUpdates(account.subData), // Recursively update subData
              };
            }
          } else {
            if (account[objectName] !== null) {
              return {
                ...account,
                [objectName]: {
                  account_id: account.id,
                  ...account[objectName],
                },
              };
            } else {
              return account; // Return unchanged account if no update is needed
            }
          }
        }
      });
      return response;
    };

    // Map through accountSettingsList and update relevant accounts
    const updatedValues = state.accountSettingsList.map((account) => {
      const { accounts, subData } = account;
      const hasSubData = accounts > 0;

      if (hasSubData) {
        // If account has subData, recursively update subData
        if (account[objectName] !== null) {
          return {
            ...account,
            [objectName]: { account_id: account.id, ...account[objectName] },
          };
        } else {
          return {
            ...account,
            subData: makeUpdates(subData),
          };
        }
      } else {
        // If no subData, return unchanged account
        if (account[objectName] !== null) {
          return {
            ...account,
            [objectName]: { account_id: account.id, ...account[objectName] },
          };
        }
        return account;
      }
    });

    // Update state immutably using Immer
    setState((draft) => {
      draft.isSettingsUpdated = false;
      draft.accountSettingsList = updatedValues;
    });
  };

  // Util function to construct accounts from accountListing
  const constructMappingObject = async (mappingArray) => {
    const objectName = payloadType === "xero" ? "xero_account" : "myob_account";
    const constructedArray = [];
    const subDataMapping = (account) => {
      const accObject = account[objectName];

      if (accObject !== null) {
        if (accObject.code?.trim()) {
          const updatedObject = {
            ...accObject,
            description: null,
            tax_rate: null,
            tracking_name1: null,
            tracking_option1: null,
            tracking_name2: null,
            tracking_option2: null,
          };
          if (accObject.description) {
            updatedObject.description = accObject.description;
          }
          if (accObject.tax_rate) {
            updatedObject.tax_rate = accObject.tax_rate;
          }
          if (accObject.tracking_name1) updatedObject.tracking_name1 = accObject.tracking_name1;

          if (accObject.tracking_option1) updatedObject.tracking_option1 = accObject.tracking_option1;

          if (accObject.tracking_name2) updatedObject.tracking_name2 = accObject.tracking_name2;

          if (accObject.tracking_option2) updatedObject.tracking_option2 = accObject.tracking_option2;
          if (
            !constructedArray.some(
              (obj) => obj.account_id === updatedObject.account_id
            )
          ) {
            constructedArray.push(updatedObject);
          }
        }
      }

      if (account.accounts > 0) {
        account.subData.forEach((subAcc) => {
          const subAccObject = subAcc[objectName];
          if (subAccObject !== null) {
            if (subAccObject?.code?.trim()) {
              const updatedObject = {
                ...subAccObject,
                description: null,
                tax_rate: null,
                tracking_name1: null,
                tracking_option1: null,
                tracking_name2: null,
                tracking_option2: null,
              };

              if (subAccObject.description) {
                updatedObject.description = subAccObject.description;
              }
              if (subAccObject.tax_rate) {
                updatedObject.tax_rate = subAccObject.tax_rate;
              }
              if (subAccObject.tracking_name1) updatedObject.tracking_name1 = subAccObject.tracking_name1;

              if (subAccObject.tracking_option1) updatedObject.tracking_option1 = subAccObject.tracking_option1;

              if (subAccObject.tracking_name2) updatedObject.tracking_name2 = subAccObject.tracking_name2;

              if (subAccObject.tracking_option2) updatedObject.tracking_option2 = subAccObject.tracking_option2;
              if (
                !constructedArray.some(
                  (obj) => obj.account_id === updatedObject.account_id
                )
              ) {
                constructedArray.push(updatedObject);
              }
            }
          }

          if (subAcc.accounts > 0) {
            subDataMapping(subAcc);
          }
        });
      }
    };

    mappingArray.forEach(subDataMapping);

    return constructedArray;
  };

  // Util function to validate settings
  const validateSettings = () => {
    return settingsValidator.current.allValid();
  };

  // Function to save settings changes
  const handleSaveUpdates = async () => {
    if (validateSettings()) {
      const mappingObject = await constructMappingObject(
        state.accountSettingsList
      );

      const payload = {
        type: payloadType,
        store_id: state.data.store_id,
        accounts: mappingObject,
      };

      if (payload.accounts.length > 0) {
        // Trigger loading
        triggerSaveButtonLoading();
        // API call to create settings
        handleCreateXeroAndMyob({ payload });
      } else {
        infoMessage("Oops, no updates found!!");
      }
    } else {
      settingsValidator.current.showMessages();
      forceUpdate(1);
      errorMessage("Account code is required !!");
    }
  };

  // useEffect to handle default store selection
  React.useEffect(() => {
    if (storeList.length > 0 && store_id) {
      setState((draft) => {
        draft.data.store_id = storeList.find((v) => v.id == store_id).id;
        draft.storeName = storeList.find((v) => v.id == store_id).name;
      });
    } else if (storeList.length > 0) {
      navigate
        (`?store_id=${storeList[0].id}`, { replace: true });
      setState((draft) => {
        draft.data.store_id = storeList[0].id;
        draft.storeName = storeList[0].name;
      });
    }
  }, [storeList, store_id]);

  // useEffect to handle account-settings list fetch
  React.useEffect(() => {
    const handler = () => {
      triggerLoading();
      // Clear search value

      if (isStoreAdmin && selectedStoreId) {
        getAccountSettingsList({ storeId: selectedStoreId });
      } else if (state.data.store_id) {
        getAccountSettingsList({ storeId: state.data.store_id });
      } else {
        triggerLoading();
      }
    };

    // Call handler
    if (xero === "2" || myob === "2") return handler();
  }, [selectedStoreId, xero, myob, state.data.store_id]);

  //function to handle connect and disconnect xero and myob 
  const handleConnectAndDisconnect = () => {
    let storeId = state.data.store_id
    let callbackUrl
    if (isStoreAdmin) callbackUrl = `${SITE_URL}store-admin/${payloadType}`
    else callbackUrl = `${SITE_URL}${payloadType}?store_id=${storeId}`

    window.location.href = `${CONNECT_SITE_URL}${payloadType}/auth/${state.connectionDetails.connection_status ? "disconnect" : "connect"}?tenantId=banjos&storeId=${storeId}&callback=${callbackUrl}`
  }


  // Function to call history Xero and Myob list
  const handleHistoryXeroMyObList = async ({
    searchText = null,
    status = null,
    syncDate = [],
    journalDate = [],
    pagination,
    sortBy = [],
  }) => {

    let params = {};
    if (searchText) {
      params = { ...params, search: searchText };
    }
    params = { ...params, type: payloadType };
    if (status) {
      params = { ...params, status };
    }

    if (state.data.store_id !== "") {
      params = { ...params, store_id: state.data.store_id };
    }

    if (Object.keys(syncDate).length) {
      params = {
        ...params,
        sync_start_date: moment(syncDate.value.startDate).format("YYYY-MM-DD"),
        sync_end_date: moment(syncDate.value.endDate).format("YYYY-MM-DD"),
      };
    }
    if (Object.keys(journalDate).length) {
      params = {
        ...params,
        journal_start_date: moment(journalDate.value.startDate).format("YYYY-MM-DD"),
        journal_end_date: moment(journalDate.value.endDate).format("YYYY-MM-DD"),
      };
    }

    if (sortBy.length > 0) {
      const sortByParams = sortBy.reduce(
        (obj, item) =>
          Object.assign(obj, { [item.id]: item.desc ? "desc" : "asc" }),
        {}
      );
      params = {
        ...params,
        sort: sortByParams,
      };
    }
    const query = {
      params,
      paramsSerializer: (params) => qs.stringify(params),
    };
    try {
      setState((draft) => {
        draft.isHistoryBusy = true;
      });
      const res = await XeroAndMyobHistoryListApi(query, pagination);
      if (res.success) {
        setState((draft) => {
          draft.isHistoryBusy = false;
          draft.history = res.data.orders
          draft.pagination = res.data.pagination;
        });
      }
    } catch (err) {
      console.log(err);

      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isHistoryBusy = false;
      });
    }
  };

  useEffect(() => {
    let isStore = isStoreAdmin ? selectedStoreId : state.data.store_id

    if (xero === "3" && title == "xero" && !!isStore && !state.syncLoading) {

      const { syncDate, journalDate, status, searchText } = payloadType === "xero" ? xeroHistory.filterApplied : myobHistory.filterApplied;
      const { pagination, sortBy } = payloadType === "xero" ? xeroHistory : myobHistory;
      const newDate = Object.keys(syncDate).length
        ? {
          endDate: new Date(syncDate.value.endDate),
          startDate: new Date(syncDate.value.startDate),
          key: "selection",
        } :
        {
          endDate: new Date(),
          startDate: new Date(),
          key: "selection",
        };
      const appliedDate = Object.keys(syncDate).length ? {
        value: newDate,
        label: `${moment(newDate.startDate).format("yyy-MM-DD")} to ${moment(
          newDate.endDate
        ).format("yyy-MM-DD")}`,
        type: "Date",
      } : {};

      const journalNewDate = Object.keys(journalDate).length
        ? {
          endDate: new Date(journalDate.value.endDate),
          startDate: new Date(journalDate.value.startDate),
          key: "selection",
        } :
        {
          endDate: new Date(),
          startDate: new Date(),
          key: "selection",
        };
      const journalAppliedDate = Object.keys(journalDate).length ? {
        value: journalNewDate,
        label: `${moment(journalNewDate.startDate).format("yyy-MM-DD")} to ${moment(
          journalNewDate.endDate
        ).format("yyy-MM-DD")}`,
        type: "Date",
      } : {};

      const filterApplied = payloadType === "xero" ? xeroHistory.filterApplied : myobHistory.filterApplied;
      setState((draft) => {
        draft.filterApplied = { ...filterApplied, syncDate: appliedDate, journalDate: journalAppliedDate };
        draft.pagination = pagination;
        draft.sortBy = sortBy;
        draft.syncDate = [newDate];
        draft.syncShowDate = Object.keys(syncDate).length ? [newDate] : [];
        draft.journalDate = [journalNewDate];
        draft.journalShowDate = Object.keys(journalDate).length ? [journalNewDate] : [];
      });

      handleHistoryXeroMyObList({
        status,
        syncDate,
        journalDate,
        searchText,
        pagination,
        sortBy
      })
    }
  }, [xero, selectedStoreId, state.data.store_id, state.syncLoading])


  // useEffect to handle Connection Detail fetch
  React.useEffect(() => {
    const handler = () => {
      handleLoading(true);

      if (isStoreAdmin && selectedStoreId) {
        getConnectionDetailList({ storeId: selectedStoreId });
      } else if (state.data.store_id) {
        getConnectionDetailList({ storeId: state.data.store_id });
      } else {
        handleLoading(false);
      }
    };

    // Call handler
    if (title == "xero" && !state.syncLoading) return handler();
  }, [selectedStoreId, state.data.store_id, state.syncLoading]);


  // Function to handle fetch list
  const getConnectionDetailList = async ({ storeId }) => {
    try {
      const response = await getConnectionDetailListApi({ storeId });
      const { success, data, message } = response;

      if (success) {
        setState((draft) => {
          draft.connectionDetails = data;
        });
        handleLoading(false);
      } else {
        handleLoading(true);
        errorMessage(message);
      }

    } catch (error) {
      errorMessage(error.response.data.message);
      handleLoading(true);

    }
  };

  // Util function to trigger loading
  const handleLoading = (state) => {

    setState((draft) => {
      draft.isLoading = state;
    });
  };

  const debounced = useDebouncedCallback((value) => {
    const { syncDate, journalDate, status } = state.filterApplied;
    const { pagination, sortBy } = state;
    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: { filterApplied: state.filterApplied },
    });
    handleHistoryXeroMyObList({
      searchText: value,
      syncDate,
      journalDate,
      status,
      pagination: {
        pageIndex: 1,
        pageSize: pagination.pageSize,
      },
      sortBy,
    });
  }, 1000);

  const onChangeFilter = (e) => {
    setState((draft) => {
      draft.filterApplied.searchText = e.target.value;
    });
    debounced(e.target.value);
  };

  const gotoPage = (page) => {
    const { syncDate, journalDate, status, searchText, } =
      state.filterApplied;
    const { sortBy, pagination } = state;
    handleHistoryXeroMyObList({
      searchText,
      syncDate,
      journalDate,
      status,
      pagination: {
        pageIndex: page,
        pageSize: pagination.pageSize,
      },
      sortBy,
    });
    filterStateDispatch({
      type: "UPDATE_PAGE_INDEX",
      page: PAGE_NAME,
      value: page,
    });
    setState((draft) => {
      draft.pagination.pageIndex = page;
    });
  };

  const setPageSize = (e) => {
    const { syncDate, journalDate, status, searchText } =
      state.filterApplied;
    const { sortBy } = state;
    handleHistoryXeroMyObList({
      searchText,
      syncDate,
      journalDate,
      status,
      pagination: {
        pageIndex: 1,
        pageSize: +e.target.value,
      },
      sortBy,
    });
    filterStateDispatch({
      type: "UPDATE_PAGE_SIZE",
      page: PAGE_NAME,
      value: +e.target.value,
    });
    setState((draft) => {
      draft.pagination.pageSize = +e.target.value;
    });
  };

  const handleSort = (sortBy) => {
    const { syncDate, journalDate, status, searchText, } =
      state.filterApplied;
    const { pagination } = state;

    handleHistoryXeroMyObList({
      searchText,
      syncDate,
      journalDate,
      status,
      pagination,
      sortBy,
    });
    filterStateDispatch({
      type: "UPDATE_SORT",
      page: PAGE_NAME,
      value: sortBy,
    });
    setState((draft) => {
      draft.sortBy = sortBy;
    });
  };

  const handleChangeStatus = (e) => {
    const { syncDate, journalDate, searchText, } =
      state.filterApplied;
    const { pagination, sortBy } = state;

    filterStateDispatch({
      type: "SET_DATA",
      page: PAGE_NAME,
      data: { filterApplied: { ...state.filterApplied, status: e.target.value, } },
    });

    handleHistoryXeroMyObList({
      searchText,
      syncDate,
      journalDate,
      status: e.target.value,
      pagination,
      sortBy,
    });
    setState((draft) => {
      draft.filterApplied.status = e.target.value;
    });
  }

  const resetFilters = () => {
    if (state.filterApplied.status || state.filterApplied.searchText || state.filterApplied.syncDate || state.filterApplied.journalDate) {
      handleHistoryXeroMyObList({
        pagination: {
          pageIndex: 1,
          pageSize: state.pagination.pageSize,
        },
        sortBy: state.sortBy,
      });
      setState((draft) => {
        draft.filterApplied.status = "";
        draft.filterApplied.searchText = "";
        draft.filterApplied.syncDate = {};
        draft.syncDate = [{
          endDate: new Date(),
          startDate: new Date(),
          key: "selection",
        }];
        draft.syncShowDate = [];
        draft.filterApplied.journalDate = {};
        draft.journalDate = [{
          endDate: new Date(),
          startDate: new Date(),
          key: "selection",
        }];
        draft.journalShowDate = [];
      });
      filterStateDispatch({
        type: "CLEAR_FILTERS",
        page: PAGE_NAME,
        value: payloadType === "xero" ? initialState.params.xeroHistory.filterApplied : initialState.params.myobHistory.filterApplied,
      });
    }
  };

  const handleIsOpen = (e, type) => {
    if (type === "sync") {
      setState((draft) => {
        draft.isSyncOpen = e;
      });
    } else {
      setState((draft) => {
        draft.isJournalOpen = e;
      });
    }
  };

  const handleDateChangeApply = (type) => {
    const { syncDate, journalDate, status, searchText, } =
      state.filterApplied;
    const { pagination, sortBy } = state;
    if (type === "sync") {
      let date = {
        value: state.syncDate[0],
        label: `${moment(state.syncDate[0].startDate).format(
          "yyy-MM-DD"
        )} to ${moment(state.syncDate[0].endDate).format("yyy-MM-DD")}`,
        type: "Date",
      };
      setState((draft) => {
        draft.syncShowDate = state.syncDate;
        draft.isSyncOpen = false;
        draft.filterApplied.syncDate = date
      });
      filterStateDispatch({
        type: "SET_DATA",
        page: PAGE_NAME,
        data: { filterApplied: { ...state.filterApplied, syncDate: date, } },
      });

      handleHistoryXeroMyObList({
        searchText,
        syncDate: date,
        journalDate,
        status,
        pagination,
        sortBy,
      });
    } else {
      let date = {
        value: state.journalDate[0],
        label: `${moment(state.journalDate[0].startDate).format(
          "yyy-MM-DD"
        )} to ${moment(state.journalDate[0].endDate).format("yyy-MM-DD")}`,
        type: "Date",
      };
      setState((draft) => {
        draft.journalShowDate = state.journalDate;
        draft.isJournalOpen = false;
        draft.filterApplied.journalDate = date
      });
      filterStateDispatch({
        type: "SET_DATA",
        page: PAGE_NAME,
        data: { filterApplied: { ...state.filterApplied, journalDate: date, } },
      });

      handleHistoryXeroMyObList({
        searchText,
        syncDate,
        journalDate: date,
        status,
        pagination,
        sortBy,
      });
    }

  };

  const onChangeDate = (e, type) => {
    if (type === "sync") {
      setState((draft) => {
        draft.syncDate = e;
      });
    } else {
      setState((draft) => {
        draft.journalDate = e;
      });
    }
  };

  const handleSync = async (date = null, store_id = null) => {
    try {
      setState((draft) => {
        draft.syncLoading = true;
      });
      const query = {
        date: date ? moment(date, "DD/MM/YYYY, hh:mm A").format('YYYY-MM-DD') : moment(state.syncDates).format('YYYY-MM-DD'),
        store_id: store_id ?? state.data.store_id
      };

      const response = await XeroAndMyobSyncApi(payloadType, query);
      const { success, data, message } = response;

      if (success) {
        setState((draft) => {
          draft.syncLoading = false;
        });
        successMessage(message)
      } else {
        setState((draft) => {
          draft.syncLoading = false;
        });
        errorMessage(message);
      }

    } catch (error) {
      setState((draft) => {
        draft.syncLoading = false;
      });
      errorMessage(error.response.data.message);
    }
  }
  const handleExportModal = (state) => {
    setState((draft) => {
      draft.exportModal = state;
    });
  }

  const handleMoreModal = (state, data) => {
    setState((draft) => {
      draft.moreModal = state;
      draft.moreTexts = data;
    });
  }

  const handleAddEmail = async (data, type) => {
    let payload
    let emails = state.connectionDetails.emails
    if (type === "add") {
      payload = {
        emails: [
          ...new Set([
            ...emails,
            data
          ]),
        ]
      }
    } else {
      payload = { emails: emails.filter((v) => data !== v) }
    }

    try {
      setState((draft) => {
        draft.isSaveButtonBusy = true;
      });
      const res = await updateXeroAndMyobSyncEmailApi(state.data.store_id, payload);
      if (res.success) {
        setState((draft) => {
          draft.isSaveButtonBusy = false;
          draft.connectionDetails = res.data
          draft.emailInput = ""
        });
      }
    } catch (err) {
      errorMessage(err.response.data.message);
      setState((draft) => {
        draft.isSaveButtonBusy = false;
      });
    }
  };

  const onInputEmailChange = (e) => {
    setState((draft) => {
      draft.emailInput = e.target.value;
    });
  };

  return {
    breadcrumbsLink,
    state,
    title,
    storeList,
    isStoreAdmin,
    dateValidator,
    settingsValidator,
    globalData,
    validateDate,
    onGenerateReport,
    handleInputChange,
    handleRowChange,
    handleSearch,
    handleSaveUpdates,
    handleSearchClear,
    filterAccountSettingsList,
    setState,
    handleConnectAndDisconnect,
    onChangeFilter,
    gotoPage,
    setPageSize,
    handleSort,
    onChangeFilter,
    handleChangeStatus,
    handleIsOpen,
    handleDateChangeApply,
    onChangeDate,
    resetFilters,
    handleSync,
    handleExportModal,
    handleMoreModal,
    handleAddEmail,
    onInputEmailChange,
  };
};
