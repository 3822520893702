import * as React from "react";
import { useParams } from "react-router-dom";
import {
  Typography,
  Card,
  Stack,
  Button,
  Chip,
  Divider,
  FormHelperText,
  TextField,
  Box,
} from "@mui/material";
import { Loader } from "../../../shared";
import InputControl from "../../../shared/components/InputControl";
import SimpleReactValidator from "simple-react-validator";
import { useSettings } from "../../Settings/hooks";
import { AsyncPaginate } from "react-select-async-paginate";
import AddIcon from "@mui/icons-material/Add";
import { Link, useNavigate } from "react-router-dom";
import { errorMessage } from "../../../utils";

export const StoreSettings = () => {
  const { id } = useParams();
  const {
    state,
    globalData,
    HandleInputChange,
    onUpdateSettings,
    handleAddMaIL,
    handleDeleteMaIL,
    handleGetUserList,
    stateTemplates,
    handleOnChange,
  } = useSettings(id);
  const { isLoading, details, isSaveButton } = state;
  const optionsTemplate = globalData?.eod_template?.map((item) => ({
    label: item.label,
    value: item.value,
  }));
  const navigate = useNavigate();
  const [input, setInput] = React.useState();
  const [, forceUpdate] = React.useState();
  const validator = React.useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );
  const validator2 = React.useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );

  const {
    variance_status,
    eod_variance,
    auto_log_status,
    auto_log_time,
    email,
    float_amount,
    keypad_id,
    pre_order_days,
    delivery_radius,
    delivery,
    delivery_provider,
    eod_template,
    cart_product_count_limit,
    cart_product_waiting_time_limit,
  } = details;
  const optionTemplates = stateTemplates?.templates?.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const onSubmit = () => {
    if (validator.current.allValid()) {
      onUpdateSettings();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const onADD = () => {
    if (validator2.current.allValid()) {
      if (email.some((v) => v.email === input)) {
        errorMessage("This email already exist.");
        setInput("");
        validator2.current.hideMessages();
      } else {
        handleAddMaIL(input);
        setInput("");
        validator2.current.hideMessages();
      }
    } else {
      validator2.current.showMessages();
      forceUpdate(1);
    }
  };

  const onInputChange = (inputValue, { action }) => {
    if (action === "input-change") {
      setInput(inputValue);
    }
  };

  const CustomOption = (props) => {
    const { data, innerRef, innerProps, isSelected } = props;
    return (
      <>
        {data.custom ? (
          <Stack
            ref={innerRef}
            {...innerProps}
            sx={{
              flexDirection: "row",
              p: "7px 8px",
              gap: "2px",
              alignItems: "center",
            }}
          >
            <AddIcon
              sx={{
                color: "#1560D4",
              }}
            />

            <Link
              target="_blank"
              sx={{ textDecoration: "none" }}
              onClick={() => navigate("/client-account/customer/create")}
            >
              Add new User
            </Link>
          </Stack>
        ) : (
          <Stack
            ref={innerRef}
            {...innerProps}
            sx={{
              flexDirection: "row",

              p: "7px 8px",

              gap: "2px",
            }}
          >
            <Stack
              sx={{
                gap: "2px",
              }}
            >
              <Typography
                variant="subtitle2"
                fontWeight={400}
                lineHeight="17px"
              >
                {data.name}
              </Typography>
              <Typography color="#666666" variant="caption" lineHeight="15px">
                {data.email}
              </Typography>
            </Stack>
          </Stack>
        )}
      </>
    );
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: "8px",
      border: "2px solid #E6E8F0",
      height: "48px",
    }),
    option: (provided, state) => ({
      ...provided,
      maxHeight: "239px",
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 99,
    }),
  };
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {validator.current.purgeFields()}
          <Card sx={{ mb: "230px", overflow: "inherit" }}>
            <Stack
              sx={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                p: "24px",
              }}
            >
              <Typography
                variant="subtitle1"
                fontWeight={600}
                lineHeight="19px"
                minWidth="200px"
              >
                Settings
              </Typography>
              <Button
                type="button"
                size="medium"
                variant="contained"
                onClick={onSubmit}
                disabled={isSaveButton}
              >
                Save
              </Button>
            </Stack>
            <Divider />
            <Stack direction="row" p="24px">
              <Typography
                variant="subtitle1"
                fontWeight={600}
                lineHeight="19px"
                minWidth="200px"
              >
                Terminal Auto Log Off
              </Typography>
              <Stack
                sx={{
                  width: "100%",
                  gap: "16px",
                }}
              >
                <Stack
                  sx={{
                    flexDirection: "row",
                    gap: "16px",
                  }}
                >
                  <InputControl
                    label="Time (In Seconds)"
                    type="number"
                    name="auto_log_time"
                    value={auto_log_time}
                    onChange={HandleInputChange}
                    isDisabledKeys={["-"]}
                    error={
                      auto_log_status
                        ? validator.current.message(
                          "Time (In Seconds)",
                          auto_log_time,
                          "required"
                        )
                        : null
                    }
                    helperText={
                      auto_log_status
                        ? validator.current.message(
                          "Time (In Seconds)",
                          auto_log_time,
                          "required"
                        )
                        : null
                    }
                    disabled={!auto_log_status}
                  />
                  <InputControl
                    type="switch"
                    name="auto_log_status"
                    value={auto_log_status}
                    onChange={(e) => {
                      HandleInputChange({
                        target: {
                          name: "auto_log_status",
                          value: e.target.checked ? 1 : 0,
                        },
                      });
                    }}
                  />
                </Stack>

                <Typography
                  variant="subtitle2"
                  color="#636363"
                  sx={{
                    display: "flex",
                    gap: "4px",
                    alignItems: "center",
                  }}
                >
                  <img
                    style={{ height: "18px" }}
                    alt="details"
                    src="/icons/ic_info.svg"
                  />{" "}
                  Default time to auto log off is 30 seconds.
                </Typography>
              </Stack>
            </Stack>
            <Divider />
            <Stack direction="row" p="24px">
              <Typography
                variant="subtitle1"
                fontWeight={600}
                lineHeight="19px"
                minWidth="200px"
              >
                EOD Variance
              </Typography>
              <Stack
                sx={{
                  width: "100%",
                  gap: "16px",
                }}
              >
                <Stack
                  sx={{
                    flexDirection: "row",
                    gap: "16px",
                  }}
                >
                  <InputControl
                    label="Variance"
                    type="number"
                    name="eod_variance"
                    value={eod_variance}
                    onChange={HandleInputChange}
                    isDoller={true}
                    isDisabledKeys={["-"]}
                    error={
                      variance_status
                        ? validator.current.message(
                          "Variance",
                          eod_variance,
                          "required"
                        )
                        : null
                    }
                    helperText={
                      variance_status
                        ? validator.current.message(
                          "Variance",
                          eod_variance,
                          "required"
                        )
                        : null
                    }
                    disabled={!variance_status}
                  />
                  <InputControl
                    type="switch"
                    name="variance_status"
                    value={variance_status}
                    onChange={(e) =>
                      HandleInputChange({
                        target: {
                          name: "variance_status",
                          value: e.target.checked ? 1 : 0,
                        },
                      })
                    }
                  />
                </Stack>

                <Typography
                  variant="subtitle2"
                  color="#636363"
                  sx={{
                    display: "flex",
                    gap: "4px",
                    alignItems: "center",
                  }}
                >
                  <img
                    style={{ height: "18px" }}
                    alt="details"
                    src="/icons/ic_info.svg"
                  />
                  User will be prompted to re-count and submit if variance is
                  greater than this value.
                </Typography>
              </Stack>
            </Stack>
            <Divider />
            <Stack direction="row" p="24px">
              <Typography
                variant="subtitle1"
                fontWeight={600}
                lineHeight="19px"
                minWidth="200px"
              >
                EOD Template
              </Typography>
              <Stack
                sx={{
                  width: "100%",
                  gap: "16px",
                }}
              >
                <Stack
                  sx={{
                    flexDirection: "row",
                    gap: "16px",
                  }}
                >
                  <InputControl
                    type="dropdown"
                    disableClearable
                    options={optionsTemplate ?? []}
                    getOptionLabel={(option) => option.label}
                    value={
                      (optionsTemplate?.length > 0 &&
                        toString(eod_template) &&
                        optionsTemplate?.find(
                          (c) => c?.value === eod_template
                        )) ||
                      null
                    }
                    onChange={(e, newValue) => {
                      handleOnChange(newValue, "eod_template");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="EOD Template"
                        required
                        error={validator.current.message(
                          "EOD Template",
                          eod_template,
                          "required"
                        )}
                        helperText={validator.current.message(
                          "EOD Template",
                          eod_template,
                          "required"
                        )}
                      />
                    )}
                  />
                </Stack>
              </Stack>
            </Stack>
            <Divider />
            <Stack direction="row" p="24px">
              <Stack direction="row" gap="4px">
                <Typography
                  variant="subtitle1"
                  fontWeight={600}
                  width="200px"
                  lineHeight="19px"
                >
                  EOD Summary Mail
                </Typography>
              </Stack>

              <Stack
                direction={"row"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Stack width="100%" gap="16px">
                  <Stack direction={"row"} gap="20px">
                    <Stack width="100%">
                      <AsyncPaginate
                        debounceTimeout={1000}
                        placeholder="Add Mail address"
                        className="AsyncPaginate-dropdown"
                        classNamePrefix="react-select"
                        isMulti
                        hideSelectedOptions={false}
                        closeMenuOnSelect={false}
                        inputValue={input}
                        onInputChange={onInputChange}
                        value={email}
                        key={JSON.stringify(email)}
                        components={{ Option: CustomOption }}
                        loadOptions={(e) => handleGetUserList({ search: e })}
                        onChange={(e, v) => {
                          if (v.action === "clear") {
                            setInput("");
                            validator2.current.hideMessages();
                          } else if (v.action === "pop-value") {
                          } else if (
                            email.some((val) => val.email === v.option.email)
                          ) {
                            errorMessage("This email already exist.");
                            setInput("");
                            validator2.current.hideMessages();
                          } else {
                            HandleInputChange({
                              target: {
                                name: "email",
                                value: e,
                              },
                            });
                            validator2.current.hideMessages();
                          }
                        }}
                        controlShouldRenderValue={false}
                        additional={{
                          page: 1,
                        }}
                        styles={customStyles}
                      />
                      <FormHelperText sx={{ color: "#C60808" }}>
                        {validator2.current.message(
                          "Email",
                          input,
                          "required|email"
                        )}
                      </FormHelperText>
                    </Stack>
                    <Button
                      sx={{
                        height: "44px !important",
                      }}
                      onClick={onADD}
                    >
                      Add
                    </Button>
                  </Stack>
                  <Stack
                    sx={{
                      flexDirection: "row",
                      alignItems: "center",
                      flexWrap: "wrap",
                      gap: "16px",
                      rowGap: "8px",
                    }}
                  >
                    {email.map((data, index) => (
                      <Chip
                        key={index}
                        label={
                          <Typography variant="subtitle2">
                            {data.name || data.email}
                          </Typography>
                        }
                        onDelete={() => {
                          handleDeleteMaIL(data.email);
                        }}
                        sx={{
                          backgroundColor: "#F2F2F2",
                          height: "30px",
                          "& .MuiChip-deleteIcon": {
                            color: "#CCCCCC",
                          },
                        }}
                      />
                    ))}
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <Divider />
            <Stack direction="row" p="24px">
              <Typography
                variant="subtitle1"
                fontWeight={600}
                lineHeight="19px"
                minWidth="200px"
              >
                Float Amount
              </Typography>
              <Stack
                sx={{
                  width: "100%",
                  gap: "16px",
                }}
              >
                <Stack
                  sx={{
                    flexDirection: "row",
                    gap: "16px",
                  }}
                >
                  <InputControl
                    label="Float Amount"
                    type="number"
                    name="float_amount"
                    value={float_amount}
                    isDoller={true}
                    onChange={HandleInputChange}
                    error={validator.current.message(
                      "Float Amount",
                      float_amount,
                      "required"
                    )}
                    helperText={validator.current.message(
                      "Float Amount",
                      float_amount,
                      "required"
                    )}
                  />
                </Stack>
              </Stack>
            </Stack>

            <Divider />
            <Stack direction="row" p="24px">
              <Stack direction="row" gap="4px">
                <Typography
                  variant="subtitle1"
                  fontWeight={600}
                  width="200px"
                  lineHeight="19px"
                >
                  Keypad Template
                </Typography>
              </Stack>

              <Stack
                direction={"row"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Stack width="100%" gap="16px">
                  <Stack direction={"row"} gap="20px">
                    <Stack width="100%">
                      <InputControl
                        type="dropdown"
                        name="conditions"
                        disableClearable
                        options={optionTemplates}
                        filteredOptions
                        getOptionLabel={(option) => option?.label}
                        onChange={(e, newValue) => {
                          handleOnChange(newValue, "keypad_id");
                        }}
                        value={
                          (optionTemplates?.length > 0 &&
                            keypad_id &&
                            optionTemplates?.find(
                              (c) => c.value === keypad_id
                            )) ||
                          null
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            sx={{
                              borderRadius: "8px",
                              ".MuiInputBase-root": { height: "44px" },
                            }}
                            label="Keypad Template"
                            required
                            error={validator.current.message(
                              "Keypad Template",
                              keypad_id,
                              "required"
                            )}
                            helperText={validator.current.message(
                              "Keypad Template",
                              keypad_id,
                              "required"
                            )}
                          />
                        )}
                      />
                    </Stack>
                    <Button
                      onClick={() =>
                        navigate(`/sales-screen-layout/edit/${keypad_id}`)
                      }
                      sx={{ minWidth: "160px" }}
                      disabled={!keypad_id}
                    >
                      View Template
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <Divider />
            <Stack direction="row" p="24px">
              <Typography
                variant="subtitle1"
                fontWeight={600}
                lineHeight="19px"
                minWidth="200px"
              >
                Delivery Available
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  gap: "16px",
                }}
              >
                <InputControl
                  type="switch"
                  name="variance_status"
                  value={delivery}
                  onChange={(e) =>
                    HandleInputChange({
                      target: {
                        name: "delivery",
                        value: e.target.checked ? 1 : 0,
                      },
                    })
                  }
                />
                <Stack
                  sx={{
                    width: "100%",
                    gap: "16px",
                  }}
                  mt={2}
                >
                  <Stack
                    sx={{
                      flexDirection: "row",
                      gap: "16px",
                    }}
                  >
                    <>
                      <InputControl
                        type="dropdown"
                        name="delivery_provider"
                        disableClearable
                        options={
                          globalData?.delivery_provider_customerApp ?? []
                        }
                        filteredOptions
                        getOptionLabel={(option) => option?.label}
                        onChange={(e, newValue) => {
                          handleOnChange(newValue, "delivery_provider");
                        }}
                        value={
                          (globalData?.delivery_provider_customerApp?.length >
                            0 &&
                            delivery_provider &&
                            globalData?.delivery_provider_customerApp?.find(
                              (c) => c.value === delivery_provider
                            )) ||
                          null
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            sx={{
                              borderRadius: "8px",
                              ".MuiInputBase-root": { height: "44px" },
                            }}
                            label="Provider"
                            required={delivery === 1}
                            error={
                              delivery === 1 &&
                              validator.current.message(
                                "Provider",
                                delivery_provider,
                                "required"
                              )
                            }
                            helperText={
                              delivery === 1 &&
                              validator.current.message(
                                "Provider",
                                delivery_provider,
                                "required"
                              )
                            }
                          />
                        )}
                      />
                      <InputControl
                        label="Radius (km)"
                        type="number"
                        name="delivery_radius"
                        value={delivery_radius}
                        onChange={HandleInputChange}
                        required={delivery === 1}
                        error={
                          delivery === 1 &&
                          validator.current.message(
                            "Radius (km)",
                            delivery_radius,
                            "required"
                          )
                        }
                        helperText={
                          delivery === 1 &&
                          validator.current.message(
                            "Radius (km)",
                            delivery_radius,
                            "required"
                          )
                        }
                      />
                    </>
                  </Stack>
                </Stack>
              </Box>
            </Stack>
            <Divider />
            <Stack direction="row" p="24px">
              <Typography
                variant="subtitle1"
                fontWeight={600}
                lineHeight="19px"
                minWidth="200px"
              >
                Pre Order Days
              </Typography>
              <Stack
                sx={{
                  width: "100%",
                  gap: "16px",
                }}
              >
                <Stack
                  sx={{
                    flexDirection: "row",
                    gap: "16px",
                  }}
                >
                  <InputControl
                    label="Days"
                    type="number"
                    name="pre_order_days"
                    value={pre_order_days}
                    onChange={HandleInputChange}
                  />
                </Stack>
              </Stack>
            </Stack>
            <Divider />
            <Stack direction="row" p="24px">
              <Typography
                variant="subtitle1"
                fontWeight={600}
                lineHeight="19px"
                minWidth="200px"
              >
                Cart Product Limit
              </Typography>
              <Stack
                sx={{
                  width: "100%",
                  gap: "16px",
                }}
              >
                <Stack
                  sx={{
                    flexDirection: "row",
                    gap: "16px",
                  }}
                >
                  <InputControl
                    label="Product Count"
                    type="number"
                    name="cart_product_count_limit"
                    value={cart_product_count_limit}
                    onChange={HandleInputChange}
                    error={
                      validator.current.message(
                        "Product Count",
                        cart_product_count_limit,
                        "required"
                      )
                    }
                    helperText={
                      validator.current.message(
                        "Product Count",
                        cart_product_count_limit,
                        "required"
                      )
                    }
                  />
                  <InputControl
                    label="Waiting Time(In minutes)"
                    type="number"
                    name="cart_product_waiting_time_limit"
                    value={cart_product_waiting_time_limit}
                    onChange={HandleInputChange}
                    error={
                      validator.current.message(
                        "Waiting Time(In minutes)",
                        cart_product_waiting_time_limit,
                        "required"
                      )
                    }
                    helperText={
                      validator.current.message(
                        "Waiting Time(In minutes)",
                        cart_product_waiting_time_limit,
                        "required"
                      )
                    }
                  />
                </Stack>
              </Stack>
            </Stack>
          </Card>
        </>
      )}
    </>
  );
};
