import { Box, Drawer, useMediaQuery } from "@mui/material";
import Logo from "../../../assets/images/Logo/Logo.png";
import Dashboard from "../../../assets/images/Icons/Dashboard.png";
import User from "../../../assets/images/Icons/Admin.png";
import Catalog from "../../../assets/images/Icons/Category.png";
import Stores from "../../../assets/images/Icons/Stores.png";
import Customers from "../../../assets/images/Icons/Customers.png";
import Sales from "../../../assets/images/Icons/ic_order.png";
import Promotions from "../../../assets/images/Icons/Promotions.png";
import Loyalty from "../../../assets/images/Icons/Loyalty.png";
import Integrations from "../../../assets/images/Icons/Integrations.png";
import EndOfDay from "../../../assets/images/Icons/insert-invitation.png";
import Help from "../../../assets/images/Icons/ic_help.svg";
import Reports from "../../../assets/images/Icons/reports.png";
import QRCode from "../../../assets/images/Icons/QRCode.png";
import Accounts from "../../../assets/images/Icons/ic_cashbook.png";
import PushNotification from "../../../assets/images/Icons/PushNotification.png";
import Cms from "../../../assets/images/Icons/cms.svg";
import version from "../../../../package.json";
import React, { useContext } from "react";
import { SidebarMenu } from ".";
import { AuthContext } from "../../../store";
import { CMS_URL } from "../../../utils";

export const DashboardSidebar = (props) => {
  const {
    auth,
    auth: { data },
  } = useContext(AuthContext);
  const { open, onClose } = props;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"), {
    defaultMatches: true,
    noSsr: false,
  });

  const items = [
    {
      href: "/",
      icon: <img alt="icon-Dashboard" alignment="center" src={Dashboard} />,
      title: "Dashboard",
      permission: "dashboard-view",
      storeAdmin: true,
    },
    {
      icon: <img alt="icon-Catalog" alignment="center" src={Catalog} />,
      title: "Catalogue",
      storeAdmin: true,
      subMenu: [
        {
          href: "/category",
          title: "Category",
          permission: "category-view",
          storeAdmin: false,
        },
        {
          href: "/products",
          title: "Products",
          storeAdmin: true,
          permission: "product-view",
        },
        {
          href: "/availabilityGroup",
          title: "Availability Group",
          permission: "availability-group-view",
          storeAdmin: false,
        },
        {
          href: "/optionalGroup",
          title: "Modifier Groups",
          permission: "optional-group-view",
          storeAdmin: false,
        },
        {
          href: "/bundle",
          title: "Bundle Groups",
          permission: "bundle-group-view",
          storeAdmin: false,
        },
      ],
    },
    {
      icon: <img alt="icon-Sales" alignment="center" src={Sales} />,
      title: "Sales",
      storeAdmin: true,
      subMenu: [
        {
          href: "/orders",
          title: "Sales Log",
          permission: "order-view",
          storeAdmin: true,
        },
        {
          href: "/refund",
          title: "Refunds",
          permission: "refund-view",
          storeAdmin: true,
        },
        {
          href: "/wastage",
          title: "Wastage",
          permission: "wastage-view",
          storeAdmin: true,
        },
        {
          href: "/discount-orders",
          title: "Discount Sales Log",
          permission: "order-view",
          storeAdmin: true,
        },
      ],
    },
    {
      icon: <img alt="icon-Promotions" alignment="center" src={Promotions} />,
      title: "Promotions",
      subMenu: [
        {
          href: "/coupons",
          title: "Coupons",
          permission: "coupon-view",
          storeAdmin: false,
        },
        {
          href: "/coupon-report",
          title: "Coupon Report",
          permission: "coupon-view",
          storeAdmin: false,
        },
        {
          href: "/discount",
          title: "Discount",
          permission: "discount-view",
          storeAdmin: false,
        },
        {
          href: "/coupon-rules",
          title: "Coupon Rules",
          permission: "condition-view",
          storeAdmin: false,
        },
        {
          href: "/gift-card",
          title: "Gift Card",
          permission: "gift-cards-view",
          storeAdmin: false,
        },
        {
          href: "/birthday-coupons",
          title: "Birthday Coupons",
          permission: "coupon-view",
          storeAdmin: false,
        },
        {
          href: "/top-selling",
          title: "Top Selling",
          permission: "product-view",
          storeAdmin: false,
        },
        {
          href: "/cart-product",
          title: "Cart Product",
          permission: "product-view",
          storeAdmin: false,
        },
      ],
    },
    {
      icon: <img alt="icon-Stores" alignment="center" src={Stores} />,
      title: "Stores",
      subMenu: [
        {
          href: "/stores",
          title: "Stores",
          permission: "store-view",
          storeAdmin: false,
        },
        {
          href: "/franchisee",
          title: "Franchisee",
          permission: "franchisee-view",
          storeAdmin: false,
        },
        {
          href: "/accounts",
          title: "Accounts",
          permission: "store-view",
          storeAdmin: true,
          superAdmin: false,
        },
      ],
    },
    {
      href: "/end-of-day",
      icon: <img alt="icon-eod" alignment="center" src={EndOfDay} />,
      title: "End of Day",
      permission: "end-of-day-view",
      storeAdmin: true,
    },
    {
      icon: <img alt="icon-Customer" alignment="center" src={Customers} />,
      title: "Customers",
      subMenu: [
        {
          href: "/customers",
          title: "Customers",
          permission: "customer-view",
          storeAdmin: false,
        },
        {
          href: "/client-account",
          title: "Client Account",
          permission: "client-account-view",
          storeAdmin: true,
          superAdmin: true,
        },
      ],
    },

    {
      icon: <img alt="icon-User" alignment="center" src={User} />,
      title: "Users",
      subMenu: [
        {
          href: "/admin-users",
          title: "Manage Users",
          permission: "user-view",
          storeAdmin: true,
        },
        {
          href: "/roles-and-permissions",
          title: "Roles & Permissions",
          permission: "role-view",
          storeAdmin: true,
        },
      ],
    },
    {
      icon: <img alt="icon-reports" alignment="center" src={Reports} />,
      href: "/reports",
      title: "Reports",
      permission: "report-view",
      storeAdmin: true,
    },
    {
      icon: (
        <img alt="icon-reports" alignment="center" src={PushNotification} />
      ),
      href: "/push-notification",
      title: "Push Notification",
      permission: "report-view",
      storeAdmin: false,
    },
    {
      icon: <img alt="icon-reports" alignment="center" src={QRCode} />,
      href: "/qr-code",
      title: "QR Code",
      permission: "qrcode-view",
      storeAdmin: false,
    },
    {
      icon: <img alt="icon-Loyalty" alignment="center" src={Loyalty} />,
      href: "/loyalty-cards",
      title: "Loyalty Cards",
      permission: "loyalty-cards-view",
      storeAdmin: false,
    },
    {
      icon: (
        <img alt="icon-Integrations" alignment="center" src={Integrations} />
      ),
      title: "Integrations",
      subMenu: [
        {
          href: "/menulog",
          title: "Menulog",
          permission: "menulog-view",
          storeAdmin: false,
        },
        {
          href: "/online-ordering",
          title: "Online Ordering",
          permission: "online-ordering-view",
          storeAdmin: false,
        },
        {
          href: "/ubereats",
          title: "UberEats",
          permission: "ubereats-view",
          storeAdmin: false,
        },
        {
          href: "/doordash",
          title: "Doordash",
          permission: "doordash-view",
          storeAdmin: false,
        },
        {
          href: "/xero",
          title: "XERO",
          permission: "xero-myob-report-view",
          storeAdmin: true,
        },
        {
          href: "/myob",
          title: "MYOB",
          permission: "xero-myob-report-view",
          storeAdmin: true,
        },
        // {
        //   href: "/xero-myob",
        //   title: "XERO & MYOB",
        //   permission: "xero-myob-report-view",
        //   storeAdmin: true,
        // },
      ],
    },
    {
      icon: <img alt="icon-Catalog" alignment="center" src={Catalog} />,
      title: "Configurations",
      storeAdmin: true,
      subMenu: [
        {
          href: "/state-and-suburb",
          title: "State & Suburb",
          permission: "state-view",
          permission2: "city-view",
          storeAdmin: false,
        },
        {
          href: "/tax-groups-and-taxes",
          title: "Taxes",
          permission: "tax-view",
          storeAdmin: false,
        },
        {
          href: "/favourites",
          title: "Favourites",
          permission: "favourite-view",
          storeAdmin: true,
        },
        {
          href: "/surcharges",
          title: "Surcharges",
          permission: "surcharge-view",
          storeAdmin: false,
        },
        {
          href: "/price-levels",
          title: "Price Levels",
          permission: "price-level-view",
          storeAdmin: false,
          permission: "price-level-view",
        },
        {
          href: "/future-price",
          title: "Future Price",
          permission: "future-price-view",
          storeAdmin: false,
        },
        {
          href: "/terminal",
          title: "Terminals",
          permission: "terminal-view",
          storeAdmin: false,
        },
        {
          href: "/manager-password",
          title: "Manager Password",
          permission: "manager-password-view",
          storeAdmin: false,
        },
        {
          href: "/bump-screen",
          title: "Bump Screens",
          permission: "bump-screen-view",
          storeAdmin: true,
          superAdmin: false,
        },
        {
          href: "/settings",
          title: "Settings",
          permission: "settings-view",
          storeAdmin: true,
          superAdmin: false,
        },
        {
          href: "/menu-banner",
          title: "Menu Banners",
          permission: "banner-view",
          storeAdmin: false,
        },
        {
          href: "/menu-template",
          title: "Menu Template",
          permission: "menu-view",
          storeAdmin: false,
        },
        {
          href: "/pos-settings",
          title: "POS Settings",
          permission: "pos-setting-view",
          storeAdmin: false,
        },
        {
          href: "/cfd",
          title: "CFD",
          permission: "cfd-view",
          storeAdmin: false,
        },
        {
          href: "/email-settings",
          title: "Email Settings",
          permission: "email-setting-view",
          storeAdmin: false,
        },
        {
          href: "/keypad",
          title: "Keypad",
          permission: "keypad-view",
          storeAdmin: false,
        },
      ],
    },
    {
      icon: <img alt="icon-account" alignment="center" src={Accounts} />,
      title: "Accounts",
      subMenu: [
        {
          href: "/list",
          title: "List",
          permission: "pl-bs-account-view",
          storeAdmin: false,
        },
        {
          href: "/pl-report",
          title: "PL Report",
          permission: "pl-bs-account-view",
          storeAdmin: false,
        },
        {
          href: "/bs-report",
          title: "BS Report",
          permission: "pl-bs-account-view",
          storeAdmin: false,
        },
        {
          href: "/coa",
          title: "Chart Of Accounts",
          permission: "coa-view",
          storeAdmin: false,
        },
        {
          href: "/configurations",
          title: "Configurations",
          permission: "coa-view",
          storeAdmin: false,
        },
      ],
    },

    {
      icon: <img alt="icon-cms" alignment="center" src={Cms} />,
      title: "CMS",
      href: `${CMS_URL}?token=${data.token}`,
      permission: "cms-view",
      storeAdmin: false,
      openInNewTab: true,
    },
    {
      icon: <img alt="icon-account" alignment="center" src={Accounts} />,
      title: "Reviews",
      subMenu: [
        {
          href: "/reviews-and-ratings",
          title: "Reviews & Ratings",
          permission: "review-rating-view",
          storeAdmin: false,
        },
        {
          href: "/products-ratings",
          title: "Product Ratings",
          permission: "review-rating-view",
          storeAdmin: false,
        },
        {
          href: "/configurations-review",
          title: "Configurations",
          permission: "review-rating-view",
          storeAdmin: false,
        },
      ],
    },
    {
      icon: <img alt="icon-User" alignment="center" src={Help} />,
      href: "https://bfitpos-help.azurewebsites.net/",
      title: "Help",
      permission: "user-view",
      storeAdmin: true,
      openInNewTab: true,
    },
  ];

  const content = (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            cursor: "pointer",
            display: "flex",
            justifyContent: "space-between",
            px: 3,
            py: "24px",
            borderRadius: 1,
          }}
        >
          <img alt="logo" alignment="center" src={Logo} />
        </Box>

        <Box sx={{ px: 2 }}>
          {items.map((item, index) => {
            return <SidebarMenu key={index} item={item} index={index} />;
          })}
        </Box>
        <Box
          sx={{ display: "flex", justifyContent: "center", marginTop: "30px" }}
        >
          Version {version.version}
        </Box>
      </Box>
    </>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open={open}
        className="sidebar-drawer"
        PaperProps={{
          sx: {
            backgroundColor: "#5E7360",
            color: "#FFFFFF",
            width: 280,
          },
        }}
        variant="persistent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      className="sidebar-drawer"
      PaperProps={{
        sx: {
          backgroundColor: "#5E7360",
          color: "#FFFFFF",
          width: 280,
        },
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};
