import { API } from "../../../utils";

export const getAccountSettingsListApi = async ({ storeId }) => {
  const response = await API.get("coas", {
    params: {
      store_id: storeId,
    },
  });
  return response.data;
};

export const downloadXeroMyobListApi = async (payload) => {
  const response = await API.post(`xero-myob-reports/export`, payload);
  return response.data;
};

export const createXeroAndMyoMappingApi = async (payload) => {
  const response = await API.post("xero-myob", payload);
  return response.data;
};

export const XeroAndMyobHistoryListApi = async (query, pagination) => {
  const { pageIndex, pageSize } = pagination;
  let url = `xero-myob?page=${pageIndex}&per_page=${pageSize}`;
  const res = await API.get(url, query);
  return res.data;
};

export const getConnectionDetailListApi = async ({ storeId }) => {
  const response = await API.get(`xero-myob/connection/xero/store/${storeId}`);
  return response.data;
};

export const XeroAndMyobSyncApi = async (type, payload) => {
  const response = await API.post(`xero-myob/sync/${type}`, payload);
  return response.data;
};

export const updateXeroAndMyobSyncEmailApi = async (storeId, payload) => {
  const response = await API.post(`xero-myob/connection/xero/store/${storeId}`, payload);
  return response.data;
};